import React from "react";
import SvgDachgeschoss from "./real-estate/Dachgeschoss";
import SvgDoppelhaushaelfte from "./real-estate/Doppelhaushaelfte";
import SvgGewerbe from "./real-estate/Gewerbe";
import SvgGrundstueck from "./real-estate/Grundstueck";
import SvgHalbvermietet from "./real-estate/Halbvermietet";
import SvgHaus from "./real-estate/Haus";
import SvgImBau from "./real-estate/ImBau";
import SvgKauf from "./real-estate/Kauf";
import SvgMehrfamilienhaus from "./real-estate/Mehrfamilienhaus";
import SvgPraxis from "./real-estate/Praxis";
import SvgReihenhaus from "./real-estate/Reihenhaus";
import SvgSonstiges from "./real-estate/Sonstiges";
import SvgUntergeschoss from "./real-estate/Untergeschoss";
import SvgVerkauf from "./real-estate/Verkauf";
import SvgVerlassen from "./real-estate/Verlassen";
import SvgVollvermietet from "./real-estate/Vollvermietet";
import SvgWohnung from "./real-estate/Wohnung";

interface IconLoaderProps {
    icon: ImmoIconName;
    color: string;
    size: string;
}

const IconLoader = (props: IconLoaderProps) => {
    const size = `${+props.size + 14}px`;
    switch (props.icon) {
        case "dachgeschoss":
            return <SvgDachgeschoss style={{ pointerEvents: "none" }} width={size} height={size} {...props} />;
        case "doppenhaushaelfte":
            return <SvgDoppelhaushaelfte style={{ pointerEvents: "none" }} width={size} height={size} {...props} />;
        case "gewerbe":
            return <SvgGewerbe style={{ pointerEvents: "none" }} width={size} height={size} {...props} />;
        case "grundstueck":
            return <SvgGrundstueck style={{ pointerEvents: "none" }} width={size} height={size} {...props} />;
        case "halbvermietet":
            return <SvgHalbvermietet style={{ pointerEvents: "none" }} width={size} height={size} {...props} />;
        case "haus":
            return <SvgHaus style={{ pointerEvents: "none" }} width={size} height={size} {...props} />;
        case "imbau":
            return <SvgImBau style={{ pointerEvents: "none" }} width={size} height={size} {...props} />;
        case "kauf":
            return <SvgKauf style={{ pointerEvents: "none" }} width={size} height={size} {...props} />;
        case "mehrfamilienhaus":
            return <SvgMehrfamilienhaus style={{ pointerEvents: "none" }} width={size} height={size} {...props} />;
        case "praxis":
            return <SvgPraxis style={{ pointerEvents: "none" }} width={size} height={size} {...props} />;
        case "reihenhaus":
            return <SvgReihenhaus style={{ pointerEvents: "none" }} width={size} height={size} {...props} />;
        case "sonstiges":
            return <SvgSonstiges style={{ pointerEvents: "none" }} width={size} height={size} {...props} />;
        case "untergeschoss":
            return <SvgUntergeschoss style={{ pointerEvents: "none" }} width={size} height={size} {...props} />;
        case "verkauf":
            return <SvgVerkauf style={{ pointerEvents: "none" }} width={size} height={size} {...props} />;
        case "verlassen":
            return <SvgVerlassen style={{ pointerEvents: "none" }} width={size} height={size} {...props} />;
        case "vollvermietet":
            return <SvgVollvermietet style={{ pointerEvents: "none" }} width={size} height={size} {...props} />;
        case "wohnung":
            return <SvgWohnung style={{ pointerEvents: "none" }} width={size} height={size} {...props} />;
        default:
            return <div />;
    }
};

export type ImmoIconName =
    | "dachgeschoss"
    | "doppenhaushaelfte"
    | "gewerbe"
    | "grundstueck"
    | "halbvermietet"
    | "haus"
    | "imbau"
    | "kauf"
    | "mehrfamilienhaus"
    | "praxis"
    | "reihenhaus"
    | "sonstiges"
    | "untergeschoss"
    | "verkauf"
    | "verlassen"
    | "vollvermietet"
    | "wohnung";

export const immoIconList = [
    "dachgeschoss",
    "doppenhaushaelfte",
    "gewerbe",
    "grundstueck",
    "halbvermietet",
    "haus",
    "imbau",
    "kauf",
    "mehrfamilienhaus",
    "praxis",
    "reihenhaus",
    "sonstiges",
    "untergeschoss",
    "verkauf",
    "verlassen",
    "vollvermietet",
    "wohnung"
];

export type ImmoIconProp = ImmoIconName | ["immo", ImmoIconName];

interface ImmoIconProps {
    icon: ImmoIconName;
    color: string;
    size: string;
    className: string;
    id: string;
}

const ImmoIcon = (props: ImmoIconProps) => {
    return <IconLoader {...props} />;
};

export default ImmoIcon;

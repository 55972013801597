import * as React from "react";

const SvgDoppelhaushaelfte = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="Doppelhaushaelfte_svg__GlobaleEbene"
        viewBox="0 0 1200 1200"
        width={props.width}
        height={props.height}
        {...props}
    >
        <g id="Doppelhaushaelfte_svg__PrimaryColorElements" fill={props.color}>
            <path d="M65.65 505.93L288 557 93 598v602l-23.7-.19-3.65-693.88z" />
            <path d="M599.6 1199.81H93.1V597.54l508.69-106.67v99.69l-2.19 609.25" />
        </g>
        <g id="Doppelhaushaelfte_svg__BaseElements">
            <path fill="#fff" d="M185.79 982.63h134.93v217.18H185.79z" />
            <path fill="#dedede" d="M18.83 1179.44h629v20.37h-629z" />
            <path
                fill="#fff"
                d="M206.1 662.19h127.23V817.2H206.1zM369.72 662.19h127.23V817.2H369.72zM369.72 993.67h71.18v78.66h-71.18zM471.35 993.67h71.18v78.66h-71.18z"
            />
            <path fill="#dedede" d="M1130.18 412.32L620.03 544.65v655.16h506.5l3.65-787.49z" />
            <path fill="#e8e8e8" d="M602.14 1189.63h496.87V596.01L600 490.87v98.26l2.14 600.5" />
            <path fill="#fff" d="M878.11 982.63h134.93v217.18H878.11z" />
            <path transform="rotate(180 867.495 1189.625)" fill="#dedede" d="M553 1179.44h629v20.37H553z" />
            <path transform="rotate(180 929.115 739.695)" fill="#fff" d="M865.5 662.19h127.23V817.2H865.5z" />
            <path transform="rotate(180 765.49 739.695)" fill="#fff" d="M701.87 662.19H829.1V817.2H701.87z" />
            <path fill="#fff" d="M757.93 993.67h71.18v78.66h-71.18zM656.3 993.67h71.18v78.66H656.3z" />
            <path
                fill="#f2f2f2"
                d="M865.5 662.19L965.83 817.2H865.5V662.19zM701.88 662.19L802.21 817.2H701.88V662.19zM369.72 662.19L470.05 817.2H369.72V662.19zM206.1 662.19L306.43 817.2H206.1V662.19z"
            />
        </g>
        <path opacity={0.06} d="M65.65 505.93L288 555.46 93 595.21V1179l-23.7-.19-3.65-672.88z" />
    </svg>
);

export default SvgDoppelhaushaelfte;

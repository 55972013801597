import React, { FC, Fragment } from "react";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { DesignGeneral } from "../../smashleads";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        typography: {
            color: (props: DesignGeneral) => props.fonts.description.color,
            fontFamily: (props: DesignGeneral) => props.fonts.description.fontFamily,
            fontWeight: (props: DesignGeneral) => +props.fonts.description.weight,
            fontSize: "0.8rem"
        }
    })
);

const NewsletterText: FC<{ design: DesignGeneral; newsletter: string }> = ({ design, newsletter }) => {
    const classes = useStyles(design);

    return (
        <Fragment>
            <Typography className={classes.typography} align={"left"}>
                {newsletter}
            </Typography>
        </Fragment>
    );
};

export default NewsletterText;

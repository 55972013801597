import React, { FC, useMemo } from "react";
import { createStyles, Grid, makeStyles, Theme, FormControlLabel, Checkbox, Card, Typography } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useForm, Controller } from "react-hook-form";
import { HandleForwardType, useLeadGeneratorDispatch, useLeadGeneratorState, QuestionType } from "@onpreo/forms";
import { DesignGeneral, QuestionTypeCheckboxes } from "../../smashleads";

const useStyles = makeStyles((muiTheme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            paddingTop: muiTheme.spacing(2),
            paddingBottom: muiTheme.spacing(4)
        },
        container: {
            maxHeight: 350
        },
        checkboxButton: {
            marginLeft: "auto",
            marginRight: "auto",
            boxShadow: (props: DesignGeneral) => props.checkboxes.boxShadow,
            width: "90%",
            borderRadius: (props: DesignGeneral) => props.checkboxes.borderRadius + "px",
            [muiTheme.breakpoints.down(880)]: {
                width: "100%"
            }
        },
        cardItem: {
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: 76,
            border: (props: DesignGeneral) => `${props.checkboxes.borderWidth}px solid ${props.checkboxes.borderColor}`,
            borderRadius: (props: DesignGeneral) => props.checkboxes.borderRadius + "px",
            backgroundColor: (props: DesignGeneral) => props.checkboxes.backgroundColor,
            boxShadow: "none",
            "&:hover": {
                border: (props: DesignGeneral) => `${props.checkboxes.hoverBorderWidth}px solid ${props.checkboxes.hoverColor}`
            }
        },
        formControlItem: {
            margin: 0,
            paddingLeft: 8,
            width: "100%"
        },
        checkbox: {
            fontSize: (props: DesignGeneral) => `${props.checkboxes.size}px`,
            color: (props: DesignGeneral) => props.checkboxes.color,
            "&$:checked": {
                color: (props: DesignGeneral) => props.checkboxes.color
            }
        },
        textfield: {
            color: (props: DesignGeneral) => props.fonts.content.color,
            fontFamily: (props: DesignGeneral) => props.fonts.content.fontFamily,
            fontSize: (props: DesignGeneral) => props.fonts.content.size,
            fontWeight: (props: DesignGeneral) => +props.fonts.content.weight,
            [muiTheme.breakpoints.down(880)]: {
                fontSize: (props: DesignGeneral) => props.fonts.content.mobileSize
            }
        },
        buttonContainer: {
            marginTop: muiTheme.spacing(2)
        }
    })
);

interface QuestionCheckboxProps {
    question: QuestionTypeCheckboxes;
    design: DesignGeneral;
    handleForward?: HandleForwardType;
    isMobile: boolean;
}

const QuestionCheckbox: FC<QuestionCheckboxProps> = props => {
    const { question, isMobile, design, handleForward } = props;
    const classes = useStyles(design);
    const dispatch = useLeadGeneratorDispatch();
    const state = useLeadGeneratorState();
    const { handleSubmit, register, reset, control } = useForm({});

    const defaultValues = useMemo(() => {
        let defaultValues: { [key: string]: boolean } = {};
        question.allCheckboxesIds.map(id => {
            defaultValues[id] = state.questions[question._id] ? (state.questions[question._id].answer as any).data[id] : false;
        });
        return defaultValues;
    }, [question._id, question.allCheckboxesIds]);

    const fieldsLen = question.allCheckboxesIds.length;
    const checkboxes = question.checkboxes;

    const handleCheck = (data: any) => {
        const answer = { checkboxes, data };
        dispatch({
            type: "updateQA",
            question: {
                questionId: question._id,
                question: question.title === "" ? question.description : question.title,
                answer: answer,
                type: QuestionType.CHECKBOX,
                restId: (question as any).restId
            }
        });
    };

    const sizer = () => {
        if (isMobile) return "100%";
        else if (fieldsLen > 8) return "33.33%";
        else if (fieldsLen > 4) return "50%";
        else return "100%";
    };
    const containerSizer = () => {
        if (isMobile) return "100%";
        else if (fieldsLen > 8) return "100%";
        else if (fieldsLen > 4) return "83%";
        else return "41.6%";
    };

    return (
        <Grid container justify={"center"} alignItems={"center"} direction={"row"} className={classes.root}>
            <form style={{ width: "100%" }}>
                <Grid
                    container
                    spacing={1}
                    style={{ width: containerSizer(), marginLeft: "auto", marginRight: "auto" }}
                    className={isMobile ? "" : classes.container}
                >
                    {Object.values(question.checkboxes).map(({ _id, name }) => {
                        return (
                            <Grid item key={_id} style={isMobile ? { paddingLeft: 0, paddingRight: 0, width: sizer() } : { width: sizer() }}>
                                <div className={classes.checkboxButton} style={isMobile ? { width: "100%" } : undefined}>
                                    <Card className={classes.cardItem}>
                                        <FormControlLabel
                                            className={classes.formControlItem}
                                            control={
                                                <Controller
                                                    name={_id}
                                                    control={control}
                                                    defaultValue={defaultValues[_id]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Checkbox
                                                            checked={value}
                                                            color={"default"}
                                                            id={"checkbox"}
                                                            icon={<CheckBoxOutlineBlankIcon className={classes.checkbox} />}
                                                            checkedIcon={<CheckBoxIcon className={classes.checkbox} />}
                                                            onChange={e => {
                                                                onChange(e.target.checked);
                                                                handleSubmit(handleCheck)();
                                                            }}
                                                        />
                                                    )}
                                                />
                                            }
                                            label={
                                                <Typography align={"left"} className={classes.textfield}>
                                                    {name}
                                                </Typography>
                                            }
                                        />
                                    </Card>
                                </div>
                            </Grid>
                        );
                    })}
                </Grid>
            </form>
        </Grid>
    );
};

export default QuestionCheckbox;

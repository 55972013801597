import { Grid, MenuItem, Theme, createStyles, makeStyles } from "@material-ui/core";
import StyledTextField from "../helpers/styled-textfield";
import { DesignGeneral, QuestionType, QuestionTypeAddress } from "../../smashleads/interfaces";
import { FC, useEffect, useState } from "react";
import { useLeadGeneratorDispatch } from "../generator-state";
import { useForm } from "react-hook-form";
import { ControlledSelect } from "@onpreo/components";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            minWidth: "90%"
        },
        steetField: { minWidth: "95%" }
    })
);

interface SimpleAddressProps {
    design: DesignGeneral;
    question: QuestionTypeAddress;
}

const defaultHelperText = "Dieses Feld ist erforderlich";

const SimpleAddress: FC<SimpleAddressProps> = ({ design, question }) => {
    const classes = useStyles();
    const [street, setStreet] = useState("");
    const [city, setCity] = useState("");
    const [houseNumber, setHouseNumber] = useState("");
    const [zip, setZip] = useState("");
    const [nation, setNation] = useState("");
    const dispatch = useLeadGeneratorDispatch();
    const { control, watch } = useForm();
    const watchFields = watch();

    const onChange = (event, variant) => {
        switch (variant) {
            case "street":
                return setStreet(event?.target.value);
            case "city":
                return setCity(event?.target.value);
            case "houseNumber":
                return setHouseNumber(event?.target.value);
            case "zip":
                return setZip(event?.target.value);
            case "nation":
                return setNation(event?.target.value);
        }
    };

    useEffect(() => {
        dispatch({
            type: "updateQA",
            question: {
                questionId: question?._id,
                question: question?.title === "" ? question?.description : question?.title,
                type: QuestionType.ADDRESS,
                restId: (question as any)?.restId,
                answer: {
                    description: `${street} ${houseNumber}, ${zip} ${city}, ${watchFields?.["nation"]}`,
                    fieldType: undefined,
                    hasHouseNumber: street !== "" && city !== "" && houseNumber !== "" && zip !== "" && watchFields?.["nation"] !== "",
                    placeId: "noAddress"
                }
            }
        });
    }, [street, city, zip, houseNumber, watchFields?.["nation"]]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={8}>
                <StyledTextField
                    value={street}
                    variant={design.textField.variant}
                    error={false}
                    design={design.textField}
                    label={"Straße"}
                    helperText={defaultHelperText}
                    required={true}
                    className={classes.steetField}
                    onChange={event => onChange(event, "street")}
                />
            </Grid>
            <Grid item xs={4}>
                <StyledTextField
                    value={houseNumber}
                    variant={design.textField.variant}
                    error={false}
                    design={design.textField}
                    label={"Hausnummer"}
                    helperText={defaultHelperText}
                    required={true}
                    className={classes.textField}
                    onChange={event => onChange(event, "houseNumber")}
                />
            </Grid>
            <Grid item xs={4}>
                <StyledTextField
                    value={zip}
                    variant={design.textField.variant}
                    error={false}
                    design={design.textField}
                    label={"PLZ"}
                    helperText={defaultHelperText}
                    required={true}
                    className={classes.textField}
                    onChange={event => onChange(event, "zip")}
                />
            </Grid>
            <Grid item xs={4}>
                <StyledTextField
                    value={city}
                    variant={design.textField.variant}
                    error={false}
                    design={design.textField}
                    label={"Stadt"}
                    helperText={defaultHelperText}
                    required={true}
                    className={classes.textField}
                    onChange={event => onChange(event, "city")}
                />
            </Grid>
            <Grid item xs={4}>
                <ControlledSelect control={control} name={"nation"} defaultValue={""} label="Land" style={{ textAlign: "left" }}>
                    <MenuItem value={"DE"}>Deutschland</MenuItem>
                    <MenuItem value={"AT"}>Österreich</MenuItem>
                    <MenuItem value={"CH"}>Schweiz</MenuItem>
                    <MenuItem value={"HU"}>Ungarn</MenuItem>
                </ControlledSelect>
            </Grid>
        </Grid>
    );
};

export default SimpleAddress;

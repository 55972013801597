import React from "react";
import { ImmoIconName } from "./immo-icon";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import dynamic from "next/dynamic";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
const ImmoIcon = dynamic(import("./immo-icon"));

const useStyles = makeStyles((muiTheme: Theme) =>
    createStyles({
        immoIcon: {
            pointerEvents: "none",
            [muiTheme.breakpoints.down("xs")]: {
                marginBottom: "15px"
            }
        }
    })
);

interface IconLoaderProps {
    icon: any;
    className?: string;
    id?: string;
    color?: string;
    size?: string;
}

const IconLoader = (props: IconLoaderProps | any) => {
    const classes = useStyles();
    const prefix = props.icon[0];
    if (prefix === "immo") {
        return (
            <ImmoIcon
                className={`${props.id} ${classes.immoIcon}`}
                id={props.id}
                icon={props.icon[1] as ImmoIconName}
                color={props.color}
                size={props.size}
            />
        );
    } else {
        const icon = `${prefix} fa-${props.icon[1]}`;
        return props.icon[1] === "check-circle" ? (
            <CheckCircleIcon
                className={`${icon} ${props.className}`}
                style={{ height: +props.size + "px", width: +props.size + "px", color: props.color }}
            />
        ) : (
            <CancelIcon className={`${icon} ${props.className}`} style={{ height: +props.size + "px", width: +props.size + "px", color: props.color }} />
        );
        // const icon = `${prefix} fa-${props.icon[1]} fa-${props.size.split("em")[0]}x`;
        // return null;
    }
};

export default IconLoader;

export const getAddressGeocodedWithoutPlaceId = async (address: string, fallbackStreetNumber?: string, envVar?: string) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${envVar}`;
    let geocodeResult: google.maps.GeocoderResult[];
    try {
        geocodeResult = await fetch(url)
            .then(response => {
                return response.json();
            })
            .then(jsonData => {
                return jsonData.results;
            });
    } catch (e) {
        throw new Error(e);
    }

    const result = geocodeResult[0];
    if (!result) throw new Error("failed to get result");

    const getComponent = (type: string): google.maps.GeocoderAddressComponent => {
        const found = result.address_components.find(c => c.types.includes(type));
        // if (!found) throw new Error("failed to find address component with type: " + type);
        // Note: we need this so that fallbacks work
        if (!found) return { short_name: "", long_name: "", types: [] };
        return found;
    };

    if (
        getComponent("country").short_name !== "DE" &&
        getComponent("country").short_name !== "AT" &&
        getComponent("country").short_name !== "CH" &&
        getComponent("country").short_name !== "HU"
    )
        throw new Error("invalid nation");

    return {
        nation: getComponent("country").short_name,
        street: getComponent("route").long_name ?? "n.V.",
        house_number: getComponent("street_number")?.long_name?.trim() !== "" ? getComponent("street_number")?.long_name : fallbackStreetNumber,
        zip: getComponent("postal_code").long_name ?? "0",
        // town: getComponent("administrative_area_level_2").long_name,   -   old version for some location is absent or has a wrong value
        town: (getComponent("locality").long_name !== "" ? getComponent("locality").long_name : getComponent("sublocality").long_name) ?? "n.V.",
        // Coordinated
        lat: +result.geometry.location.lat,
        lng: +result.geometry.location.lng
    };
};

import { QuestionType } from "@onpreo/forms";
import dotProp from "dot-prop";
import { State } from "../generator-state";
import { Valuation } from "../sprengnetter";

export function parseQuiz(lead: State) {
    let object = {};

    const questions = lead.questions;
    const contact = lead.contact;

    dotProp.set(object, "contact", contact);

    let count = 1;
    Object.values(questions).forEach(question => {
        const keys = Object.keys(object);
        if (question.restId) {
            if (keys.includes(question.restId as string) && question.type !== QuestionType.CHECKBOX) {
                dotProp.set(object, question.restId + "_" + count, question.answer);
                count = count + 1;
            } else if (question.type === QuestionType.CHECKBOX) {
                const obj = question?.answer as any;
                if (obj) {
                    let ans = {};
                    let checkboxes = obj?.checkboxes;
                    let data = obj?.data;
                    if (checkboxes)
                        Object.keys(checkboxes).map(entry => {
                            ans[checkboxes[entry].name] = data[entry];
                        });
                    object[question.restId] = ans;
                }
            } else dotProp.set(object, question.restId, question.answer);
        }
    });

    return object;
}
export const prepareLead = (state: State, valuation: Valuation) => {
    return {
        ...parseQuiz(state),
        address: valuation?.address_geocoded,
        category: valuation?.category
    };
};

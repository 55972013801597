import * as React from "react";

const SvgGrundstueck = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="Grundstueck_svg__GlobaleEbene"
        viewBox="0 0 1200 1200"
        width={props.width}
        height={props.height}
        {...props}
    >
        <g id="Grundstueck_svg__BaseElements">
            <path fill="#f3f2f1" d="M693.56 768.12l502.02 192.89-732.46 238.61L4.58 898.94l688.98-130.82z" />
            <path
                style={{
                    isolation: "isolate"
                }}
                fill="#555"
                opacity={0.1}
                d="M693.56 768.12l-54.87 10.42-44.11 139.2 601 43.27-502.02-192.89z"
            />
        </g>
        <path
            d="M594.58 917.74l-83.71-294.16C478.89 515.44 556 473 600.08 473c44.67 0 123.13 40.06 88 149.85z"
            fill={props.color}
            id="Grundstueck_svg__PrimaryColorElements"
        />
        <path
            d="M507.35 610l87.23 307.72L653.7 731.2 542.28 493.43s-39 25.63-39 80.27c.03 22.59 4.07 36.3 4.07 36.3z"
            opacity={0.06}
        />
    </svg>
);

export default SvgGrundstueck;

import * as React from "react";

const SvgUntergeschoss = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="Untergeschoss_svg__GlobaleEbene"
        viewBox="0 0 1199 1199"
        width={props.width}
        height={props.height}
        {...props}
    >
        <g id="Untergeschoss_svg__BaseElements">
            <path fill="#e8e8e8" d="M1027.85 1179.24H489.86v-818.8l537.99 123.58v695.22z" />
            <path fill="#e8e8e8" d="M489.86 1179.24H175.67V812.88h314.91l-.72 366.36z" />
            <path
                fill="#f9f9f9"
                d="M527.73 525.04h275.3v125.07h-275.3zM718.23 687.82h275.3v125.07h-275.3zM215.51 850.6h275.3v125.07h-275.3zM715.63 850.6h275.3v125.07h-275.3zM842.64 525.04h150.89v125.07H842.64zM527.73 687.82h157.99v125.07H527.73zM527.77 850.6h150.89v125.07H527.77z"
            />
            <path
                fill="#f2f2f2"
                d="M372.83 850.6l93.47 125.06h-93.47L285.32 850.6h87.51zM850.3 687.82l93.46 125.06H850.3l-87.51-125.06h87.51zM682.75 525.04l93.46 125.07h-93.46l-87.51-125.07h87.51z"
            />
            <path fill="#bcbcbc" d="M115.39 1179.24h969v19.38h-969z" />
        </g>
        <g id="Untergeschoss_svg__PrimaryColorElements" fill={props.color}>
            <path d="M308.99 1013.38h275.3v125.07h-275.3zM620.25 1013.38h275.3v125.07h-275.3zM215.51 1013.38h56.56v125.07h-56.56zM934.37 1013.38h56.56v125.07h-56.56z" />
        </g>
    </svg>
);

export default SvgUntergeschoss;

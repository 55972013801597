import * as React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { FilledInput } from "@onpreo/components";
import { BaseTextFieldProps, FilledInputProps, OutlinedInputProps, InputProps, TextField, Grid } from "@material-ui/core";
import { DesignTextField } from "../types";
import { forwardRef } from "react";

// https://stackoverflow.com/questions/54325908/change-outline-for-outlinedinput-with-react-material-ui
// https://github.com/mui-org/material-ui/issues/13347
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            textAlign: "center",
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            width: "60%",
            /* padding: "12px 0px 0px 0px", */
            [theme.breakpoints.down("xs")]: {
                marginLeft: theme.spacing(0),
                marginRight: theme.spacing(0),
                width: "100%"
            }
        },
        label: {
            color: (props: DesignTextField) => props.baseColor,
            "&$focused": {
                color: (props: DesignTextField) => props.focusedColor
            },
            "&$error": {
                color: (props: DesignTextField) => props.errorColor
            }
        },
        rootInput: {
            backgroundColor: (props: DesignTextField) => (props.variant !== "standard" ? props.backgroundColor : ""),
            paddingTop: 8,
            /* padding: "0.7em 1em em 1em", */
            "&:hover": {
                backgroundColor: (props: DesignTextField) => (props.variant !== "standard" ? props.backgroundColor : "")
            },
            "&$focused": {
                backgroundColor: (props: DesignTextField) => (props.variant !== "standard" ? props.backgroundColor : "")
            },
            "&:hover $notchedOutline": {
                border: (props: DesignTextField) => "2px solid " + props.hoverColor
            },
            "&$focused $notchedOutline": {
                border: (props: DesignTextField) => "2px solid " + props.focusedColor
            },
            "&.Mui-error $notchedOutline": {
                border: (props: DesignTextField) => "1px solid " + props.errorColor
            }
        },
        input: {
            width: "100%"
        },
        notchedOutline: {
            border: (props: DesignTextField) => "2px solid " + props.baseColor
        },
        focused: {
            color: (props: DesignTextField) => props.focusedColor
        },
        underline: {
            "&:before": {
                borderBottom: (props: DesignTextField) => "1px solid " + props.baseColor
            },
            "&:after": {
                borderBottom: (props: DesignTextField) => "2px solid " + props.focusedColor
            },
            "&:hover:not($focused):not($error):before": {
                borderBottom: (props: DesignTextField) => "2px solid " + props.hoverColor
            },
            "&.Mui-error:after": {
                borderBottom: (props: DesignTextField) => "1px solid " + props.errorColor
            }
        },
        error: {
            color: (props: DesignTextField) => props.errorColor
        }
    })
);

interface StyledTextFieldProps extends BaseTextFieldProps {
    error?: boolean;
    variant: any;
    design: DesignTextField;
    onChange?(event: any): void;
    onBlur?(event: any): void;
    inputProps?: any;
    InputProps?: Partial<InputProps> | Partial<FilledInputProps> | Partial<OutlinedInputProps>;
}

const StyledTextField = forwardRef<HTMLInputElement, StyledTextFieldProps>((props, ref) => {
    const { design, error, helperText, ...other } = props;
    const classes = useStyles(design);

    return (
        <Grid container justifyContent={"center"}>
            {/* @ts-ignore */}
            <FilledInput error={error ? { message: helperText } : undefined} className={classes.root} {...other} ref={ref} />
        </Grid>
    );
});

StyledTextField.displayName = "StyledTextField";

export default StyledTextField;

import { Grid, Typography } from "@material-ui/core";
import { FC } from "react";
import {
    QuestionTypeAddress,
    QuestionTypeCheckboxes,
    QuestionTypeContact,
    QuestionTypeIcons,
    QuestionTypeSlider,
    QuestionTypeText,
    QuestionTypeThankYou,
    QuestionTypeValuation
} from "../smashleads/interfaces/Question";
import PageBase from "./page-base";
import QuestionAddress from "./questions/question-address";
import { HandleForwardType, Question, QuestionType } from "./types";
import QuestionIcons from "./questions/question-icons";
import QuestionSlider from "./questions/question-slider";
import QuestionThankYou from "./questions/question-thank-you";
import QuestionContact from "./questions/question-contact";
import QuestionValuation from "./questions/question-valuation";
import QuestionPipeline, { QuestionTypePipeline } from "./questions/question-pipeline";
import { AsyncThunk } from "@reduxjs/toolkit";
import QuestionCheckbox from "./questions/question-checkbox";
import QuestionText from "./questions/question-text";
import { PipelineDocument, RealEstateDocument } from "@onpreo/database";
import QuestionStart, { QuestionTypeStart } from "./questions/question-start";

interface LeadGeneratorProps {
    page: Question;
    pageNumber: number;
    handleForward: HandleForwardType;
    handleBackward(): void;
    isMobile: boolean;
    design: any;
    onCreate?: AsyncThunk<any, any, {}>;
    envVar?: string;
    hasValuation?: boolean | "enabled" | "disabled";
    defaultContact?: any;
    hasAddress?: "enabled" | "disabled";
    partnersList?: any[];
    onCheckContactExists?: (email: string) => Promise<{ email: string; name: string; phone: string }>;
    accessedPipelines?: PipelineDocument[];
    evaluationType?: "sprengnetter" | "priceHubble";
    preOwner?: { name: string; email: string; phone: string };
    realEstate?: RealEstateDocument;
    workspaceId?: string;
    dispatchFlag?: boolean;
    tags?: string[];
    /* supportedFeatures: Features;
     * legalObligations: LegalObligationsInfo;
     * landingPage: LandingPageSettings;
     * tracking: TrackingInfo; */
    /* timeStamp: number;
     * lang: "en" | "de"; */
}

export const PageLoader: FC<LeadGeneratorProps> = ({
    page,
    pageNumber,
    handleForward,
    handleBackward,
    isMobile,
    design,
    onCreate,
    envVar,
    hasValuation,
    defaultContact,
    hasAddress,
    partnersList,
    onCheckContactExists,
    accessedPipelines,
    evaluationType,
    preOwner,
    realEstate,
    workspaceId,
    dispatchFlag,
    tags
    /* supportedFeatures,
     * legalObligations,
     * landingPage,
     * tracking, */
    /* timeStamp,
     * lang */
}) => {
    const PageRenderer = (type: QuestionType) => {
        switch (type) {
            case QuestionType.ICONS:
                return (
                    <QuestionIcons
                        question={page as unknown as QuestionTypeIcons}
                        design={design.design}
                        handleForward={handleForward}
                        evaluationType={evaluationType}
                    />
                );
            case QuestionType.ADDRESS:
                return (
                    <QuestionAddress
                        question={page as unknown as QuestionTypeAddress}
                        design={design.design}
                        handleForward={handleForward}
                        envVar={envVar}
                    />
                );
            case QuestionType.SLIDER:
                return <QuestionSlider question={page as unknown as QuestionTypeSlider} design={design.design} handleForward={handleForward} lang={"de"} />;
            case QuestionType.WELCOME:
                return null;
            case QuestionType.THANK_YOU:
                return <QuestionThankYou question={page as unknown as QuestionTypeThankYou} design={design.design} isMobile={false} />;
            case QuestionType.CHECKBOX:
                return <QuestionCheckbox question={page as unknown as QuestionTypeCheckboxes} design={design.design} isMobile={false} />;
            case QuestionType.TEXT:
                return <QuestionText question={page as unknown as QuestionTypeText} design={design.design} handleForward={handleForward} />;
            case QuestionType.LOADING:
                return null;
            case QuestionType.CONTACT:
                return (
                    <QuestionContact
                        question={page as unknown as QuestionTypeContact}
                        design={design.design}
                        handleBackward={handleBackward}
                        handleForward={handleForward}
                        onCreate={onCreate}
                        hasValuation={hasValuation}
                        defaultContact={defaultContact}
                        onCheckContactExists={onCheckContactExists}
                        preOwner={preOwner}
                        dispatchFlag={dispatchFlag}
                    />
                );
            case QuestionType.VALUATION:
                return (
                    <QuestionValuation
                        design={design.design}
                        hasValuation={hasValuation}
                        handleForward={handleForward}
                        question={page as unknown as QuestionTypeValuation}
                        onCreate={onCreate}
                        envVar={envVar}
                        hasAddress={hasAddress}
                        evaluationType={evaluationType}
                        realEstate={realEstate}
                        workspaceId={workspaceId}
                        contactId={defaultContact?._id}
                    />
                );

            case QuestionType.PIPELINE:
                return (
                    <QuestionPipeline
                        design={design.design}
                        handleForward={handleForward}
                        question={page as unknown as QuestionTypePipeline}
                        partnersList={partnersList}
                        accessedPipelines={accessedPipelines}
                        tags={tags}
                    />
                );

            case QuestionType.START:
                return (
                    <QuestionStart
                        design={design.design}
                        handleForward={handleForward}
                        question={page as unknown as QuestionTypeStart}
                        realEstate={realEstate}
                    />
                );
            default:
                return <Typography>{type}</Typography>;
        }
    };

    return (
        <PageBase
            question={page}
            handleForward={handleForward}
            pageNumber={pageNumber}
            handleBackward={handleBackward}
            isMobile={isMobile}
            design={design["design"]}
        >
            <Grid container>{PageRenderer(page.type)}</Grid>
        </PageBase>
    );
};

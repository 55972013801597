import { DesignGeneral } from "../smashleads/interfaces/Design";
import { QuizDocument } from "../smashleads/models/Quiz";

type Questions = QuizDocument["questions"];
export type Question = Questions[keyof Questions];

type UsedTypes = Question["type"];

// export type DesignGeneral = typeof design["design"];
export type DesignButtons = DesignGeneral["buttons" | "backButton"];
export type DesignTextField = DesignGeneral["textField"];

export enum FieldType {
    STREET = "street",
    HOUSE_NUMBER = "houseNumber",
    ZIP_CODE = "zipCode",
    CITY = "city"
}

export interface AddressAnswer {
    description: string;
    placeId: string;
    hasHouseNumber: boolean;
    structured_formatting?: {
        main_text: string;
        main_text_matched_substrings: { length: number; offset: number }[];
        secondary_text: string;
        secondary_text_matched_substrings?: { length: number; offset: number }[];
    };
    fieldType?: FieldType;
    terms?: any;
}

export type HandleForwardType = (next: string, answer?: string) => void;

export enum QuestionType {
    ICONS,
    SLIDER,
    TEXT,
    DROPDOWN,
    CHECKBOX,
    WELCOME, // used
    CONTACT, // used
    THANK_YOU, // used
    IMAGES,
    MESSAGE,
    LOADING, // used
    BIG_IMAGES,
    ADDRESS, // used
    VALUATION, // used
    FIRST_SELECTION,
    CHECK_TO_INPUT,
    BUBBLE,
    PIPELINE,
    START
}

import * as React from "react";

const SvgGewerbe = (props: React.SVGProps<SVGSVGElement>) => (
    <svg id="Gewerbe_svg__GlobaleEbene" viewBox="0 0 1199 1199.93" width={props.width} height={props.height} {...props}>
        <g id="Gewerbe_svg__PrimaryColorElements" fill={props.color}>
            <path d="M689.99 637.36l16.46-268.9h98.65l14.66 240.99M923.65 873.18l30.03-506.1h98.65l25.97 566.8" />
        </g>
        <g id="Gewerbe_svg__BaseElements">
            <path
                fill="#e8e8e8"
                d="M299.6 1198.88l-260.26-.38.2-605.85 404.58-105.7-.23 109.17 414.45-104.75v707.51H285.76"
            />
            <path
                fill="#d1d1d1"
                d="M1162.08 969.39L858.52 829.37v370.56h303.56V969.39zM53.52 1198.88H39.54V592.65L53.52 589v609.88zM458.44 1198.88h-13.98l-.57-602.76 14.55-3.47v606.23z"
            />
            <path fill="#d1d1d1" d="M39.34 1178.61h1122.75v21.32H39.34z" />
            <path
                fill="#fff"
                d="M102.65 932.26h292.11v246.35H102.65zM512.43 932.26h292.11v246.35H512.43zM102.65 812.22h130.82v58.87H102.65zM262.44 812.22h132.32v58.87H262.44zM512.43 813.48h130.82v58.87H512.43zM672.21 813.48h132.32v58.87H672.21z"
            />
        </g>
    </svg>
);

export default SvgGewerbe;

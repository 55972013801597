import * as React from "react";

const SvgHalbvermietet = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="Halbvermietet_svg__GlobaleEbene"
        viewBox="0 0 1200 1200.43"
        width={props.width}
        height={props.height}
        {...props}
    >
        <g id="Halbvermietet_svg__BaseElements">
            <path fill="#dedede" d="M283.06 287.19l592.15 153.47v759.77H287.3l-4.24-913.24z" />
            <path fill="#e8e8e8" d="M902.84 1200.43H314.93V502l590.45-123.72V493.9l-2.54 706.53" />
            <path fill="#fff" d="M422.51 948.57h156.62v251.86H422.51z" />
            <path fill="#dedede" d="M228.72 1176.81h730.11v23.62H228.72z" />
            <path fill="#fff" d="M437.73 576.97h147.68v179.76H437.73z" />
            <path fill="#f2f2f2" d="M437.73 576.97l116.35 179.76H437.73V576.97z" />
        </g>
        <path
            d="M610.15 440.14v736.67h292.69l2.54-798.53-149.77 31.38-145.34-37.89z"
            fill={props.color}
            id="Halbvermietet_svg__PrimaryColorElements"
        />
        <path
            fill="#fff"
            d="M753.97 961.38h82.62v91.22h-82.62zM636.01 576.97h147.68v179.76H636.01zM641.95 961.38h82.62v91.22h-82.62z"
        />
        <path fill="#f2f2f2" d="M636.01 576.97l116.35 179.76H636.01V576.97z" />
        <path opacity={0.06} d="M610.15 440.14v-68.18l145.47 37.7-145.47 30.48z" />
    </svg>
);

export default SvgHalbvermietet;

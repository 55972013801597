import * as React from "react";

const SvgDachgeschoss = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="Dachgeschoss_svg__GlobaleEbene"
        viewBox="0 0 1200 1200.48"
        width={props.width}
        height={props.height}
        {...props}
    >
        <g id="Dachgeschoss_svg__BaseElements">
            <path fill="#e8e8e8" d="M1022 1181.09H484.02v-818.8L1022 485.87v695.22z" />
            <path fill="#e8e8e8" d="M484.02 1181.09h-314.2V814.73h314.92l-.72 366.36z" />
            <path
                fill="#f9f9f9"
                d="M712.39 689.67h275.3v125.07h-275.3zM209.66 852.45h275.3v125.07h-275.3zM709.79 852.45h275.3v125.07h-275.3zM303.14 1015.23h275.3v125.07h-275.3zM614.4 1015.23h275.3v125.07H614.4zM521.88 689.67h157.99v125.07H521.88zM521.93 852.45h150.89v125.07H521.93zM209.66 1015.23h56.56v125.07h-56.56zM928.52 1015.23h56.56v125.07h-56.56z"
            />
            <path
                fill="#f2f2f2"
                d="M366.99 852.45l93.46 125.06h-93.46l-87.51-125.06h87.51zM844.46 689.67l93.46 125.06h-93.46l-87.51-125.06h87.51zM743.34 1015.23l93.46 125.06h-93.46l-87.51-125.06h87.51z"
            />
            <path fill="#bcbcbc" d="M115.31 1181.09h969v19.38h-969z" />
        </g>
        <path fill={props.color} d="M521.88 526.89h275.3v125.07h-275.3zM836.8 526.89h150.89v125.07H836.8z" />
    </svg>
);

export default SvgDachgeschoss;

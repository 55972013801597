import React, { FC, useEffect, useState } from "react";
import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import { QuestionTypeThankYou } from "../../smashleads/interfaces/Question";
import CustomButton from "../button";
import { DesignGeneral } from "../../smashleads/interfaces/Design";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1
        },
        row: {
            minHeight: 200,
            maxHeight: 400
        }
    })
);

interface QuestionThankYouProps {
    question: QuestionTypeThankYou;
    design: DesignGeneral;
    isMobile: boolean;
}

const QuestionThankYou: FC<QuestionThankYouProps> = props => {
    const { question, design, isMobile } = props;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container direction={"row"} justify={"center"} alignItems={"center"} className={classes.row}>
                {question.hasButton && (
                    <CustomButton
                        size="small"
                        design={design.buttons}
                        contentName={props.question.title}
                        style={{
                            flex: isMobile ? 1 : undefined,
                            width: "auto",
                            padding: isMobile ? "8px 0px 8px 0px" : undefined
                        }}
                    >
                        {question.button}
                    </CustomButton>
                )}
            </Grid>
        </div>
    );
};

export default QuestionThankYou;

import * as React from "react";

const SvgVerlassen = (props: React.SVGProps<SVGSVGElement>) => (
    <svg id="Verlassen_svg__GlobaleEbene" viewBox="0 0 1200 1200" width={props.width} height={props.height} {...props}>
        <g id="Verlassen_svg__BaseElements">
            <path fill="#dedede" d="M289.2 284.68l592.88 153.65v760.69H293.45l-4.25-914.34z" />
            <path fill="#e8e8e8" d="M909.74 1199.02H321.11V499.74l591.17-123.86v115.75l-2.54 707.39" />
            <path fill="#dedede" d="M234.79 1175.38h731v23.65h-731z" />
            <path fill="#bcbcbc" d="M452.43 574.8h147.86v179.98H452.43zM642.59 574.8h147.86v179.98H642.59z" />
            <path fill="#fff" d="M642.59 959.68h82.72v91.33h-82.72zM760.69 959.68h82.72v91.33h-82.72z" />
            <path
                fill="#f2f2f2"
                d="M452.43 574.8l31.58 21.09-21 6.23 21 20.92h-19.44l14.54 41.75 20.25 8.45-10.91 33.23 16.1 21.8 6.13 15.74 15.68-24.56 12.84 25.44 14.15-14.02 15.57 23.91H452.43V574.8z"
            />
            <path fill="#bcbcbc" d="M428.83 946.85h146.16v228.52H428.83z" />
            <path
                fill="#f2f2f2"
                d="M790.45 754.78l-34.92-12.93 24.21-14.34-20.98-20.95 8.38-15.89-3.44-25.85-20.55 6.27 11.23-47.96-22.18-.09-.01-37.47-15.7 24.55-12.82-25.45-14.16 14.01-15.55-23.93 116.49.1v179.93z"
            />
        </g>
        <path
            fill={props.color}
            d="M428.83 946.86l110.6 20.96v230.95l-110.6-23.39V946.86z"
            id="Verlassen_svg__PrimaryColorElements"
        />
    </svg>
);

export default SvgVerlassen;

import * as React from "react";

const SvgVerkauf = (props: React.SVGProps<SVGSVGElement>) => (
    <svg id="Verkauf_svg__GlobaleEbene" viewBox="0 0 1200 1200.02" width={props.width} height={props.height} {...props}>
        <g id="Verkauf_svg__BaseElements">
            <path fill="#dedede" d="M287.8 286.78l592.16 153.47v759.77H292.04l-4.24-913.24z" />
            <path fill="#e8e8e8" d="M907.59 1200.02H319.67V501.59l590.45-123.71v115.61l-2.53 706.53" />
            <path fill="#dedede" d="M233.46 1176.4h730.11v23.62H233.46z" />
            <path
                fill="#fff"
                d="M450.83 576.56h147.68v179.76H450.83zM640.75 576.56h147.68v179.76H640.75zM640.75 960.97h82.62v91.22h-82.62zM758.72 960.97h82.62v91.22h-82.62z"
            />
            <path
                fill="#f5f5f5"
                d="M450.83 576.56l116.35 179.76H450.83V576.56zM640.75 576.56l116.36 179.76H640.75V576.56z"
            />
        </g>
        <g id="Verkauf_svg__PrimaryColorElements">
            <path fill={props.color} d="M427.26 948.16h156.62v228.5H427.26z" />
            <path
                fill="#fff"
                stroke={props.color}
                strokeMiterlimit={10}
                strokeWidth={10}
                d="M714.96 686.4h443.43v205.74H714.96z"
            />
            <path fill={props.color} d="M731.27 703.05h409.8v171.9h-409.8z" />
        </g>
        <text
            transform="matrix(.96 0 0 1 767.54 843.68)"
            fontSize={148.892}
            fill="#fff"
            fontFamily="ProximaNova-Bold,Proxima Nova"
            fontWeight={700}
        >
            {"100\u20AC"}
        </text>
    </svg>
);

export default SvgVerkauf;

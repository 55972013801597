import React, { Fragment } from "react";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { DesignGeneral, LegalDescriptionInterface } from "../../smashleads";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        typography: {
            color: (props: DesignGeneral) => props.fonts.description.color,
            fontFamily: (props: DesignGeneral) => props.fonts.description.fontFamily,
            fontWeight: (props: DesignGeneral) => +props.fonts.description.weight,
            fontSize: "0.8rem"
        }
    })
);

const convertToHttps = (link: string) => {
    if (link === "") return "#";
    if (link.substring(0, 7) == "http://") {
        link = "https://" + link.substring(7);
        return link;
    } else if (link.substring(0, 8) !== "https://") {
        link = "https://" + link;
        return link;
    } else return link;
};

interface LegalNoteProps {
    legalObligations: any;
    design: DesignGeneral;
    legalDescription: LegalDescriptionInterface;
}

const LegalNote = (props: LegalNoteProps) => {
    const {
        legalObligations,
        design,
        legalDescription: { isUserAgreementDisabled, legalDescription, userAgreement, dataProtection }
    } = props;
    const classes = useStyles(design);

    return (
        <Fragment>
            <Typography className={classes.typography} align={"left"}>
                {legalDescription[0] + " "}
                {!isUserAgreementDisabled && (
                    <a
                        target={"_blank"}
                        rel={"noopener noreferrer"}
                        href={
                            legalObligations && legalObligations.userAgreement
                                ? convertToHttps(legalObligations.userAgreement)
                                : "https://www.onpreo.com/terms-of-service"
                        }
                        style={{
                            textDecoration: "underline",
                            color: design.fonts.description.color,
                            fontWeight: "bold"
                        }}
                    >
                        {userAgreement}
                    </a>
                )}
                {" " + legalDescription[1] + " "}
                <a
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                    href={
                        legalObligations && legalObligations.dataProtection
                            ? convertToHttps(legalObligations.dataProtection)
                            : "https://www.onpreo.com/privacy-policy"
                    }
                    style={{
                        textDecoration: "underline",
                        color: design.fonts.description.color,
                        fontWeight: "bold"
                    }}
                >
                    {dataProtection}
                </a>
                {" " + legalDescription[2]}
            </Typography>
        </Fragment>
    );
};

export default LegalNote;

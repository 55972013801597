import React, { FC } from "react";
import { createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { HandleForwardType } from "@onpreo/forms";
import { DesignGeneral, QuestionBase } from "../../smashleads";
import { Address, RealEstateDocument } from "@onpreo/database";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            minHeight: 200,
            maxHeight: 400,
            padding: theme.spacing(0),
            marginTop: "-5px"
        }
    })
);

export function parseAddress(address: Address) {
    if (address)
        return typeof address === "string" ? address : `${address?.street} ${address?.house_number}, ${address?.zip} ${address?.town}, ${address?.nation}`;
    else return "Keine Straße 0, 0 Keine Stadt, Kein Land";
}

export interface QuestionTypeStart extends QuestionBase {}

interface QuestionTextProps {
    question: QuestionTypeStart;
    design: DesignGeneral;
    handleForward: HandleForwardType;
    realEstate: RealEstateDocument;
}

const QuestionStart: FC<QuestionTextProps> = props => {
    const { question, design, handleForward, realEstate } = props;
    const classes = useStyles();

    return (
        <Grid className={classes.container} container justify={"space-around"} alignItems={"center"}>
            {realEstate && (
                <>
                    <Grid item xs={12} style={{ marginTop: 16 }}>
                        <Typography variant="h4"> {`Adresse: ${parseAddress(realEstate?.address)}`}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4"> {`Kategorie: ${realEstate?.category}`}</Typography>
                    </Grid>
                    {realEstate?.construction && (
                        <Grid item xs={12}>
                            <Typography variant="h4"> {`Konstruktionsart: ${realEstate?.construction}`}</Typography>
                        </Grid>
                    )}
                    {realEstate?.plotArea && (
                        <Grid item xs={12}>
                            <Typography variant="h4"> {`Grundstücksfläche: ${realEstate?.plotArea}m²`}</Typography>
                        </Grid>
                    )}
                    {realEstate?.livingArea && (
                        <Grid item xs={12}>
                            <Typography variant="h4"> {`Wohnfläche: ${realEstate?.livingArea}m²`}</Typography>
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    );
};

export default QuestionStart;

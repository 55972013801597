import { Fragment, useState, useMemo, useEffect } from "react";
import { Grid, Typography, makeStyles, Theme, createStyles, useMediaQuery } from "@material-ui/core";
import { useRouter } from "next/router";
import { useForm, FormProvider } from "react-hook-form";
import { LeadGeneratorStateProvider, useLeadGeneratorState, useLeadGeneratorDispatch, LeadContact } from "../generator-state";
import { QuestionType, HandleForwardType, AddressAnswer } from "../types";
import {
    QuestionTypeAddress,
    QuestionTypeIcons,
    QuestionTypeSlider,
    QuestionTypeContact,
    QuestionTypeValuation
} from "../../smashleads/interfaces/Question";
import AddressField from "./address-field";
import QuestionSelect from "./question-select";
import QuestionFieldValue from "./question-field-value";
import { getDefaultQuestionIds, valuationStep, pipelineStep } from "./default-values";
import QuestionContact from "./question-contact";
import QuestionPipeline, { QuestionTypePipeline } from "../questions/question-pipeline";
import QuestionValuation from "../questions/question-valuation";
import PageNextButton from "../page-next-button";

const useStyles = makeStyles((muiTheme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "end"
        }
    })
);

interface FormFields extends LeadContact {
    form: string;
}

const SimpleFormLoader = ({
    onFinish,
    onCreate,
    envVar,
    quiz,
    design,
    hasValuation,
    hasAddress,
    onCheckContactExists,
    accessedPipelines,
    evaluationType,
    tags,
    tippgeberBooked
}) => {
    const classes = useStyles(design);
    const state = useLeadGeneratorState();
    const router = useRouter();
    const isMobile = useMediaQuery("(max-width:600px)");
    const methods = useForm();
    const dispatch = useLeadGeneratorDispatch();

    const today = new Date();
    const currentYear = today.getFullYear();
    const range = (start, end, step) => {
        return Array.from(Array.from(Array(Math.ceil((end - start + 1) / step)).keys()), x => start + x * step);
    };
    const yearList = range(1900, currentYear, 1).reverse();

    const [currentQuestions, setCurrentQuestions] = useState(getDefaultQuestionIds(hasAddress));
    const [categoryValue, setCategoryValue] = useState("default");
    const [existingValue, setExistingValue] = useState("default");
    const [step, setStep] = useState<"form" | "valuation">("form");

    const disabled = useMemo(() => {
        let disabledOptions = [];

        currentQuestions.forEach(q => {
            const question = quiz.questions[q];

            if (question.type === QuestionType.ADDRESS) {
                if (state.questions[question._id] === undefined) {
                    disabledOptions = [...disabledOptions, true];
                } else {
                    const answer = state.questions[question._id].answer as unknown as AddressAnswer;
                    disabledOptions = [...disabledOptions, !answer.hasHouseNumber];
                }
            }

            //@ts-ignore
            if (question.type === QuestionType.PIPELINE) {
                if (state.questions[question._id] === undefined) {
                    disabledOptions = [...disabledOptions, true];
                } else {
                    const { tag, tab, gsoAktion, days, voucher, deadline, partnerId, customPipeline, isCustom } = state.questions[question._id]
                        .answer as unknown as {
                        tag?: string;
                        tab: number;
                        gsoAktion?: string;
                        days?: string;
                        voucher?: string;
                        deadline?: string;
                        partnerId?: string;
                        customPipeline?: string;
                        isCustom?: boolean;
                    };

                    const disabledGsoTag =
                        tag === "GSO" && (gsoAktion === undefined || days === undefined || voucher === undefined || deadline === undefined);
                    const disabledIfCustom = isCustom && (customPipeline === undefined || customPipeline === "default");
                    const disablesIfNotCustom = !isCustom && disabledGsoTag;
                    const disabledFirstTab = tab === 0 && (disablesIfNotCustom || disabledIfCustom);

                    disabledOptions = [...disabledOptions, disabledFirstTab];
                }
            }

            const disValue =
                (question.type === QuestionType.SLIDER || question.type === QuestionType.ICONS) &&
                (!state.questions[question._id] ||
                    !state.questions[question._id].answer ||
                    state.questions[question._id].answer === "" ||
                    (question.type === QuestionType.SLIDER && state.questions[question._id].answer === "Keine Angabe"));

            disabledOptions = [...disabledOptions, disValue];
        });

        return disabledOptions.includes(true);
    }, [state.contact, Object.keys(state.questions).map(q => state.questions[q])]);

    const handleSave: HandleForwardType = async (next, answer) => {
        const pipeline = state.questions["content_pipeline"]?.answer?.["tab"];
        const isCustom = state.questions["content_pipeline"]?.answer?.["isCustom"];
        const customPipeline = state.questions["content_pipeline"]?.answer?.["customPipeline"];
        const basePath = router.basePath;
        const handleReturn = (path: string) => {
            router.push(path);
            onFinish();
        };
        if (quiz.questions[next].type === QuestionType.THANK_YOU) {
            if (onFinish) {
                if (pipeline !== undefined && !isCustom) {
                    switch (pipeline) {
                        case 0:
                            return handleReturn(`${basePath}/pipelines/setting/online`);
                        case 1:
                            return handleReturn(`${basePath}/pipelines/setting/offline`);
                        case 2:
                            if (tippgeberBooked) return handleReturn(`${basePath}/pipelines/tipper-leads`);
                            else return handleReturn(`${basePath}/pipelines/partnership`);
                        default:
                            return onFinish();
                    }
                } else if (isCustom) {
                    const p = accessedPipelines.reduce((acc, p) => {
                        if (p?.subcategories?.length > 0) acc = [...acc, ...p.subcategories];
                        else acc = [...acc, p];
                        return acc;
                    }, []);
                    const selectedPipeline = p.find(item => item?._id === customPipeline);
                    return handleReturn(`${basePath}/pipelines/${selectedPipeline?.route}`);
                }
                onFinish();
            }
            return;
        }

        await handleSaveContact();
        setStep("valuation");
    };

    const handleSaveContact = async () => {
        const data: FormFields | any = methods.getValues();

        if (data.form) return methods.setError("form", { type: "validation" });
        if (Object.keys(methods.formState.errors).length === 0) {
            data.origin = state.origin;
            await dispatch({ type: "updateContact", contact: data });
        }
    };

    const [visibleSaveContactBtn, setVisibleSaveContactBtn] = useState(true);
    useEffect(() => {
        if (hasAddress === "disabled") {
            setVisibleSaveContactBtn(false);
        }
    }, [hasAddress]);
    const handleAddPipelineStep = () => {
        if (hasAddress === "enabled") {
            setVisibleSaveContactBtn(false);
            setCurrentQuestions(prev => [...prev, pipelineStep]);
        }
    };

    const InputRenderer = (type: QuestionType, page) => {
        switch (type) {
            case QuestionType.ICONS:
                return (
                    <QuestionSelect
                        question={page as unknown as QuestionTypeIcons}
                        design={design.design}
                        setCurrentQuestions={setCurrentQuestions}
                        categoryValue={categoryValue}
                        setCategoryValue={setCategoryValue}
                        hasAddress={hasAddress}
                        existingValue={existingValue}
                        setExistingValue={setExistingValue}
                    />
                );
            case QuestionType.ADDRESS:
                return <AddressField question={page as unknown as QuestionTypeAddress} design={design.design} envVar={envVar} />;
            case QuestionType.SLIDER:
                return (
                    <QuestionFieldValue
                        question={page as unknown as QuestionTypeSlider}
                        design={design.design}
                        lang={"de"}
                        yearList={page.restId === "constructionYear" || page.restId === "existingConstructionYear" ? yearList : undefined}
                    />
                );
            case QuestionType.WELCOME:
                return null;
            case QuestionType.LOADING:
                return null;
            case QuestionType.CONTACT:
                return (
                    <FormProvider {...methods}>
                        <QuestionContact
                            question={page as unknown as QuestionTypeContact}
                            design={design.design}
                            onCheckContactExists={onCheckContactExists}
                            onAfterSave={handleAddPipelineStep}
                            visibleSaveContactBtn={visibleSaveContactBtn}
                        />
                    </FormProvider>
                );
            case QuestionType.PIPELINE:
                return (
                    <Fragment>
                        <Grid item xs={12}>
                            <Typography style={{ textAlign: "center" }} variant="h3">
                                Pipeline
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ marginLeft: 16, marginRight: 16, marginTop: -8 }}>
                            <QuestionPipeline
                                design={design.design}
                                question={page as unknown as QuestionTypePipeline}
                                accessedPipelines={accessedPipelines}
                                tags={tags}
                            />
                        </Grid>
                    </Fragment>
                );
            default:
                return;
        }
    };

    return (
        <Grid item className={classes.root}>
            {step === "form" && (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography style={{ textAlign: "center", fontSize: 24 }} variant="h2">
                            Legen Sie eine neue Immobilie an
                        </Typography>
                    </Grid>
                    {currentQuestions.map(q => (
                        <Fragment key={q}>{InputRenderer(quiz.questions[q].type, quiz.questions[q])}</Fragment>
                    ))}
                </Grid>
            )}
            {step === "valuation" && (
                <Grid container spacing={1}>
                    <Grid item xs={12} style={{ marginTop: 16 }}>
                        <Typography style={{ textAlign: "center" }} variant="h1">
                            {quiz.questions[valuationStep].title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <QuestionValuation
                            design={design.design}
                            hasValuation={hasValuation}
                            handleForward={handleSave}
                            question={quiz.questions[valuationStep] as unknown as QuestionTypeValuation}
                            onCreate={onCreate}
                            envVar={envVar}
                            hasAddress={hasAddress}
                            evaluationType={evaluationType}
                        />
                    </Grid>
                </Grid>
            )}

            {step === "form" && !visibleSaveContactBtn && (
                <PageNextButton
                    value={quiz.questions[valuationStep].button}
                    design={design.design}
                    handleForward={() => handleSave(valuationStep)}
                    isMobile={!!isMobile}
                    contentName={quiz.questions[valuationStep].title}
                    disabled={disabled}
                />
            )}
        </Grid>
    );
};

export const NewLeadSimpleForm = ({
    onFinish,
    onCreate,
    envVar,
    quiz,
    design,
    hasValuation,
    hasAddress,
    onCheckContactExists,
    accessedPipelines,
    evaluationType,
    tags,
    tippgeberBooked
}) => {
    return (
        <LeadGeneratorStateProvider>
            <SimpleFormLoader
                onFinish={onFinish}
                onCreate={onCreate}
                quiz={quiz}
                design={design}
                envVar={envVar}
                hasValuation={hasValuation}
                hasAddress={hasAddress}
                onCheckContactExists={onCheckContactExists}
                accessedPipelines={accessedPipelines}
                evaluationType={evaluationType}
                tags={tags}
                tippgeberBooked={tippgeberBooked}
            />
        </LeadGeneratorStateProvider>
    );
};

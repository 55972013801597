import { Question, QuestionType } from "./types";
import { QuizDocument } from "../smashleads/models/Quiz";

export const findEntry = (quiz: QuizDocument) => {
    const validQuestions: { [key: string]: Question } = {};
    let allValidQuestionIds: string[] = [];
    let entryPoint;
    for (const i of quiz.allQuestionIds) {
        const question = quiz.questions[i];
        if (!question.isInQuiz) continue;
        allValidQuestionIds = [...allValidQuestionIds, question._id];
        validQuestions[i] = question;
        if (!entryPoint && question.type === QuestionType.WELCOME) entryPoint = question._id;
    }
    return [allValidQuestionIds, entryPoint];
};

import React, { FC } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { useBackwardTransitionStyles, useForwardTransitionStyles } from "./transition-styles";

export interface Transition {
    type: "slide" | "zoom" | "fade" | "none";
    duration: number; // in ms
}

const AnimationLoader: FC<{
    childKey: string;
    isForward: boolean;
    forward: Transition;
    backward: Transition;
    disable: boolean;
    isMobile?: boolean;
}> = props => {
    const forwardClasses = useForwardTransitionStyles(props.forward.duration);
    const backwardClasses = useBackwardTransitionStyles(props.backward.duration);

    if (props.disable || (props.isForward && props.forward.type === "none") || (!props.isForward && props.backward.type === "none")) {
        return <div style={{ display: "flex", flex: 1, maxWidth: "100%" }}>{props.children}</div>;
    }

    const slideClassNames = {
        enter: forwardClasses.slideEnter,
        enterActive: forwardClasses.slideEnterActive,
        exit: forwardClasses.slideExit,
        exitActive: forwardClasses.slideExitActive
    };
    const slideBackClassNames = {
        enter: backwardClasses.slideEnter,
        enterActive: backwardClasses.slideEnterActive,
        exit: backwardClasses.slideExit,
        exitActive: backwardClasses.slideExitActive
    };
    const fadeClassNames = {
        enter: forwardClasses.fadeEnter,
        enterActive: forwardClasses.fadeEnterActive,
        exit: forwardClasses.fadeExit,
        exitActive: forwardClasses.fadeExitActive
    };
    const fadeBackClassNames = {
        enter: backwardClasses.fadeEnter,
        enterActive: backwardClasses.fadeEnterActive,
        exit: backwardClasses.fadeExit,
        exitActive: backwardClasses.fadeExitActive
    };
    const zoomClassNames = {
        enter: forwardClasses.zoomEnter,
        enterActive: forwardClasses.zoomEnterActive,
        exit: forwardClasses.zoomExit,
        exitActive: forwardClasses.zoomExitActive
    };
    const zoomBackClassNames = {
        enter: backwardClasses.zoomEnter,
        enterActive: backwardClasses.zoomEnterActive,
        exit: backwardClasses.zoomExit,
        exitActive: backwardClasses.zoomExitActive
    };

    let classNames = {};
    if (props.isForward) {
        if (props.forward.type === "slide") classNames = slideClassNames;
        if (props.forward.type === "fade") classNames = fadeClassNames;
        if (props.forward.type === "zoom") classNames = zoomClassNames;
    } else {
        if (props.backward.type === "slide") classNames = slideBackClassNames;
        if (props.backward.type === "fade") classNames = fadeBackClassNames;
        if (props.backward.type === "zoom") classNames = zoomBackClassNames;
    }

    return (
        <SwitchTransition>
            <CSSTransition
                key={props.childKey}
                /*@ts-ignore*/
                addEndListener={(node, done) => {
                    node.addEventListener("transitionend", done, false);
                }}
                classNames={classNames}
            >
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        overflowX: "hidden",
                        margin: !!props.isMobile ? "-42px -30px -42px -30px" : "-16px -30px -16px -30px",
                        padding: !!props.isMobile ? "42px 30px 42px 30px" : "16px 30px 16px 30px"
                    }}
                >
                    <div className={"transition-container"} style={{ display: "flex", flex: 1 }}>
                        {props.children}
                    </div>
                </div>
            </CSSTransition>
        </SwitchTransition>
    );
};

export default AnimationLoader;

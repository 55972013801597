import React, { FC, useEffect, useState } from "react";
import { Box, createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import { AddressAnswer, HandleForwardType } from "../types";
import { OptionalValuationQuestions, QuestionTypeValuation } from "../../smashleads/interfaces/Question";
import { State, useLeadGeneratorDispatch, useLeadGeneratorState } from "../generator-state";
import { AddressGeocoded, Valuation, ValuationResponse } from "../sprengnetter";
import ValuationProgress from "./valuation-progress";
import PageNextButton from "../page-next-button";
import { validateCategory, validateOptionalQuestion } from "./valuation-validation";
import { parseQuiz, prepareLead } from "./prepare-lead";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import useScript from "../helpers/use-script";
import { useDispatch } from "react-redux";
import { DesignGeneral } from "../../smashleads/interfaces/Design";
import { AsyncThunk } from "@reduxjs/toolkit";
import { RealEstateDocument } from "@onpreo/database";
import { getAddressGeocodedWithoutPlaceId } from "../helpers/geocode";

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            flexGrow: 1,
            minHeight: 200
        },
        arrowIcon: {
            marginRight: 8
        },
        circularBar: {
            color: (props: DesignGeneral) => `${props.loading.color}`
        },
        typographyBig: {
            color: (props: DesignGeneral) => props.fonts.description.color,
            fontFamily: (props: DesignGeneral) => props.fonts.description.fontFamily,
            fontWeight: 600,
            fontSize: (props: DesignGeneral) => props.fonts.description.size * 2.5
        },
        typography: {
            color: (props: DesignGeneral) => props.fonts.description.color,
            fontFamily: (props: DesignGeneral) => props.fonts.description.fontFamily,
            fontWeight: (props: DesignGeneral) => +props.fonts.description.weight,
            fontSize: (props: DesignGeneral) => props.fonts.description.size,
            marginLeft: 8
        }
    })
);

interface PageValuationProps {
    question: QuestionTypeValuation;
    design: DesignGeneral;
    handleForward: HandleForwardType;
    onCreate?: AsyncThunk<any, any, {}>;
    envVar?: string;
    hasValuation: "enabled" | "disabled" | boolean;
    hasAddress: "enabled" | "disabled";
    evaluationType?: "sprengnetter" | "priceHubble";
    realEstate?: RealEstateDocument;
    workspaceId?: string;
    contactId?: string;
}

const numberFormat = (x?: number) => {
    if (x === undefined) return "-";
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const geocodeByPlaceId = (placeId: string): Promise<google.maps.GeocoderResult[]> => {
    const geocoder = new window.google.maps.Geocoder();
    const OK = window.google.maps.GeocoderStatus.OK;
    return new Promise((resolve, reject) => {
        geocoder.geocode({ placeId }, (results, status) => {
            if (status !== OK) reject(status);
            if (results === null) throw new Error("no results");
            resolve(results);
        });
    });
};

const getAddressGeocoded = async (placeId: string, fallbackStreetNumber?: string) => {
    // NOTE(luca): We need to do this request client side
    let geocodeResult: google.maps.GeocoderResult[];
    try {
        geocodeResult = await geocodeByPlaceId(placeId);
    } catch (e) {
        throw new Error(e);
    }

    const result = geocodeResult[0];
    if (!result) throw new Error("failed to get result");

    const getComponent = (type: string): google.maps.GeocoderAddressComponent => {
        const found = result.address_components.find(c => c.types.includes(type));
        // if (!found) throw new Error("failed to find address component with type: " + type);
        // Note: we need this so that fallbacks work
        if (!found) return { short_name: "", long_name: "", types: [] };
        return found;
    };

    if (
        getComponent("country").short_name !== "DE" &&
        getComponent("country").short_name !== "AT" &&
        getComponent("country").short_name !== "CH" &&
        getComponent("country").short_name !== "HU"
    )
        throw new Error("invalid nation");

    return {
        // NOTE(luca): short_name should always equal the ISO-2 Norm
        nation: getComponent("country").short_name,
        street: getComponent("route").long_name,
        house_number: getComponent("street_number")?.long_name !== "" ? getComponent("street_number")?.long_name : fallbackStreetNumber,
        zip: getComponent("postal_code").long_name,
        // town: getComponent("administrative_area_level_2").long_name,   -   old version for some location is absent or has a wrong value
        town: getComponent("locality").long_name !== "" ? getComponent("locality").long_name : getComponent("sublocality").long_name,
        // Coordinated
        lat: result.geometry.location.lat(),
        lng: result.geometry.location.lng()
    };
};

const QuestionValuation: FC<PageValuationProps> = props => {
    const { question, design, handleForward, onCreate, envVar, hasValuation, hasAddress, evaluationType, realEstate, workspaceId, contactId } = props;
    const classes = useStyles(design);
    const state = useLeadGeneratorState();
    const dispatch = useDispatch();
    const [error, setError] = useState(false);
    const [answer, setAnswer] = useState<ValuationResponse | undefined>(undefined);
    const completed = hasAddress === "disabled" || (answer !== undefined && !error);
    const [livingArea, setLivingArea] = useState(0);
    const leadGendispatch = useLeadGeneratorDispatch();
    const currency = answer?.currency === "CHF" ? "CHF" : "€";

    const getAddressAnswer = () => {
        const qa = state.questions[question.addressQuestion];
        if (qa && qa.answer) {
            return qa.answer as unknown as AddressAnswer;
        }
    };

    const getCategoryAnswer = () => {
        const qa = state.questions[question.categoryQuestion];
        if (qa && qa.answer) return qa.answer as string;
    };

    const loadResult = async () => {
        if (hasAddress === "enabled") {
            const qa = state.questions[question.addressQuestion];
            const placeId = (qa?.answer as unknown as AddressAnswer)?.placeId;
            const addressAnswer = getAddressAnswer();
            const categoryAnswer = getCategoryAnswer();
            if (!addressAnswer || !categoryAnswer) {
                leadGendispatch({
                    type: "updateValuationError",
                    valuationError: true
                });
                setError(true);
                return;
            }
            const category = validateCategory(categoryAnswer);
            if (category === undefined) {
                leadGendispatch({
                    type: "updateValuationError",
                    valuationError: true
                });
                // Should never happen since that should be validated in the frontend
                console.error("Failed to parse valuation category");
                setError(true);
                return;
            }

            let address_geocoded: AddressGeocoded;
            try {
                const fallbackStreetNumber = addressAnswer?.description?.split(",")[0]?.split(" ").slice(-1)[0] ?? "2";
                address_geocoded =
                    placeId === "noAddress"
                        ? await getAddressGeocodedWithoutPlaceId(addressAnswer?.description, fallbackStreetNumber, envVar)
                        : await getAddressGeocoded(addressAnswer.placeId, fallbackStreetNumber);
            } catch (e) {
                console.log(e);
                leadGendispatch({
                    type: "updateValuationError",
                    valuationError: true
                });
                setError(true);
                return;
            }

            console.log(address_geocoded);

            if (Object.values(address_geocoded).includes("")) {
                leadGendispatch({
                    type: "updateValuationError",
                    valuationError: true
                });
                setError(true);
            } else {
                const optionalFields = Object.assign({}, ...Object.keys(OptionalValuationQuestions).map(x => ({ [x]: undefined })));
                if (question.optionalQuestions !== undefined) {
                    Object.entries(question.optionalQuestions).forEach(([key, value]) => {
                        if (value !== undefined) {
                            const type = key as OptionalValuationQuestions;

                            if (Array.isArray(value)) {
                                // So we might have multiple questions connected to this type.
                                // We will use **the first** question with an answer (eg. answer !== undefined)
                                const answer = (value as string[]).map(id => state.questions[id] && state.questions[id].answer).find(a => a !== undefined);
                                if (answer !== undefined) optionalFields[type] = validateOptionalQuestion(type, answer as string, category);
                            } else {
                                const answer = state.questions[value] && state.questions[value].answer;
                                if (answer !== undefined) optionalFields[type] = validateOptionalQuestion(type, answer as string, category);
                            }
                        }
                    });
                }

                console.log("Optional Fields value: ");
                console.log(optionalFields);

                const valuation: Valuation = {
                    address_geocoded,
                    category,
                    optionalFields
                };

                const lead = prepareLead(state, valuation);
                //@ts-ignore
                setLivingArea(+lead?.livingArea);
                const result = await dispatch(
                    onCreate({
                        state,
                        valuation,
                        lead,
                        hasValuation,
                        evaluationType,
                        realEstateId: realEstate?._id,
                        workspaceId: workspaceId,
                        contactId: contactId
                    })
                );
                setAnswer(result["payload"]);
            }
        } else {
            const lead = parseQuiz(state);
            await dispatch(onCreate({ lead }));
        }
    };

    const scriptSrc = `https://maps.googleapis.com/maps/api/js?key=${envVar}&libraries=places`;
    const status = useScript(scriptSrc);

    useEffect(() => {
        if (status === "ready") loadResult().then(() => {});
    }, [question._id, status]);

    /* useEffect(() => {
     *     if (!question.showValuation && completed) handleForward(question.target);
     * }, [completed]); */

    if (error)
        return (
            <Grid container item className={classes.root} direction={"row"} justify={"center"} alignItems={"center"} xs={12}>
                <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                    <Typography className={classes.typography}>
                        Die angegebenen Adressdaten konnten nicht gefunden werden.
                        <br /> Bitte überprüfen Sie diese oder wenden Sie sich an den Support.
                    </Typography>
                </Grid>
                <PageNextButton
                    value={question.button}
                    design={design}
                    hideBackward={true}
                    handleForward={() => handleForward(question.target)}
                    isMobile={false}
                />
            </Grid>
        );

    return (
        <Grid container item className={classes.root} direction={"row"} justify={"center"} alignItems={"center"} xs={12}>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                {completed ? (
                    <Grid container>
                        {hasValuation === "enabled" && answer && (
                            <>
                                <Grid item xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Typography className={classes.typographyBig}>
                                        {numberFormat(Number(answer && answer?.value)) + ` ${currency ?? "€"}`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} container justify={"center"} alignItems={"center"}>
                                    <Grid item style={{ margin: 8, display: "flex", alignItems: "center" }}>
                                        <KeyboardArrowDown className={classes.arrowIcon} color={"error"} style={{ width: 20, height: 20 }} />
                                        <Typography className={classes.typography}>{numberFormat(answer && answer.min) + ` ${currency ?? "€"}`}</Typography>
                                    </Grid>
                                    <Grid item style={{ margin: 8, display: "flex", alignItems: "center" }}>
                                        <KeyboardArrowUp className={classes.arrowIcon} htmlColor={"#2dce89"} style={{ width: 20, height: 20 }} />
                                        <Typography className={classes.typography}>{numberFormat(answer && answer.max) + ` ${currency ?? "€"}`}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container justify={"center"} alignItems={"center"}>
                                    <Grid item style={{ margin: 8, display: "flex", alignItems: "center" }}>
                                        <KeyboardArrowDown className={classes.arrowIcon} color={"error"} style={{ width: 20, height: 20 }} />
                                        <Typography className={classes.typography}>
                                            {numberFormat(answer && Math.round(answer.min / livingArea)) + ` ${currency ?? "€"}/m²`}
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{ margin: 8, display: "flex", alignItems: "center" }}>
                                        <KeyboardArrowUp className={classes.arrowIcon} htmlColor={"#2dce89"} style={{ width: 20, height: 20 }} />
                                        <Typography className={classes.typography}>
                                            {numberFormat(answer && Math.round(answer.max / livingArea)) + ` ${currency ?? "€"}/m²`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <PageNextButton
                                isMobile={false}
                                value={question.button}
                                design={design}
                                hideBackward={true}
                                handleForward={() => handleForward(question.target)}
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <Box position="relative" display="flex" style={{ width: "100%", alignItems: "center", justifyContent: "center" }}>
                        <ValuationProgress loadingType={question.loadingType} design={design} />
                    </Box>
                )}
            </Grid>
        </Grid>
    );
};

export default QuestionValuation;

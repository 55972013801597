export enum ContactType {
    FULL_NAME,
    FIRST_NAME,
    LAST_NAME,
    EMAIL,
    PHONE,
    DATE,
    STREET,
    POSTAL_CODE,
    CITY,
    COUNTRY,
    COMPANY,
    WEBSITE,
    SECTOR,
    CUSTOM
}

export const ContactTypeList = [
    ContactType.FULL_NAME,
    ContactType.EMAIL,
    ContactType.FIRST_NAME,
    ContactType.LAST_NAME,
    ContactType.PHONE,
    ContactType.DATE,
    ContactType.STREET,
    ContactType.POSTAL_CODE,
    ContactType.CITY,
    ContactType.COUNTRY,
    ContactType.COMPANY,
    ContactType.WEBSITE,
    ContactType.SECTOR,
    ContactType.CUSTOM
];

export enum QuestionType {
    ICONS,
    SLIDER,
    TEXT,
    DROPDOWN,
    CHECKBOX,
    WELCOME,
    CONTACT,
    THANK_YOU,
    IMAGES,
    MESSAGE,
    LOADING,
    BIG_IMAGES,
    ADDRESS,
    VALUATION,
    FIRST_SELECTION,
    CHECK_TO_INPUT,
    BUBBLE
}

export enum ExtraContentType {
    TITLE,
    DESCRIPTION,
    MESSAGE,
    IMAGE,
    BUTTON,
    DIVIDER,
    VIDEO,
    CHECK_LIST,
    TWO_IMAGES,
    SOCIAL_MEDIA,
    IMAGE_GROUP
}

export const questionDesign: { [key in QuestionType]: string[] } = {
    [QuestionType.WELCOME]: [],
    [QuestionType.ADDRESS]: [],
    [QuestionType.VALUATION]: [],
    [QuestionType.ICONS]: ["icons"],
    [QuestionType.SLIDER]: ["slider"],
    [QuestionType.TEXT]: ["textField"],
    [QuestionType.DROPDOWN]: [],
    [QuestionType.CHECKBOX]: ["checkboxes"],
    [QuestionType.VALUATION]: [],
    [QuestionType.CONTACT]: ["contact"],
    [QuestionType.THANK_YOU]: [],
    [QuestionType.IMAGES]: ["images"],
    [QuestionType.MESSAGE]: [],
    [QuestionType.LOADING]: ["loading"],
    [QuestionType.BIG_IMAGES]: ["bigImages"],
    // Map First Selection also to bigImages design
    [QuestionType.FIRST_SELECTION]: ["bigImages"],
    [QuestionType.CHECK_TO_INPUT]: ["checkboxes", "slider"],
    [QuestionType.BUBBLE]: ["bubble"]
};

export enum LogoPosition {
    TOP_LEFT,
    TOP_MID,
    TOP_RIGHT,
    BOTTOM_LEFT,
    BOTTOM_MID,
    BOTTOM_RIGHT
}

// Helper interfaces
export interface QuestionCheckbox {
    _id: string;
    name: string;
}

export interface QuestionBubble {
    _id: string;
    name: string;
}

export interface QuestionIcon {
    _id: string;
    name: string;
    icon: string;
    target: string;
    iconColor: string;
    tag: {
        name: string;
        value: string;
    };
    position?: {
        top: number;
        left: number;
    };
}

export interface QuestionCheckToInput {
    _id: string;
    name: string;
    slider: {
        name: string;
        min: number;
        max: number;
        unit: string;
    };
}

export interface QuestionImage {
    _id: string;
    name: string;
    image: string;
    compatImage: string;
    target: string;
    tag: {
        name: string;
        value: string;
    };
    position?: {
        top: number;
        left: number;
    };
}

export interface QuestionBigImage {
    _id: string;
    name: string;
    image: string;
    compatImage: string;
    target: string;
    tag: {
        name: string;
        value: string;
    };
    position?: {
        top: number;
        left: number;
    };
}

export interface QuestionContactField {
    type: ContactType;
    required: boolean;
    _id: string;
    helperText: string;
}

export interface LegalDescriptionInterface {
    legalDescription: [string, string, string];
    isUserAgreementDisabled: boolean;
    userAgreement: string;
    dataProtection: string;
}

export type SourceTuple = [string, string];

// BASE Question
export interface QuestionBase {
    _id: string;
    type: QuestionType;
    logos: Logos;
    title: string;
    description: string;
    isImageUploadOpen: boolean;
    isDirty: boolean;
    isInQuiz: boolean;
    top: number;
    left: number;
    target: string;
    sources: SourceTuple[];
    required: boolean;
    button: string;
    elements: string[];
    extraContent: { [key: string]: ExtraContent };
}

export type QuestionIcons = { [key: string]: QuestionIcon };
export type QuestionImages = { [key: string]: QuestionImage };
export type QuestionCheckboxes = { [key: string]: QuestionCheckbox };
export type QuestionCheckToInputs = { [key: string]: QuestionCheckToInput };
export type QuestionBubbles = { [key: string]: QuestionBubble };
export type IQuestionBigImages = { [key: string]: QuestionBigImage };
export type QuestionContactFields = { [key: string]: QuestionContactField };

interface Logos {
    [LogoPosition.TOP_LEFT]: string;
    [LogoPosition.TOP_MID]: string;
    [LogoPosition.TOP_RIGHT]: string;
    [LogoPosition.BOTTOM_LEFT]: string;
    [LogoPosition.BOTTOM_MID]: string;
    [LogoPosition.BOTTOM_RIGHT]: string;
}

// Question Type Interfaces
export type QuestionTypeWelcome = QuestionBase;

export interface QuestionTypeIcons extends QuestionBase {
    icons: QuestionIcons;
    allIconIds: string[];
}

export interface QuestionTypeImages extends QuestionBase {
    images: QuestionImages;
    allImageIds: string[];
}

export interface QuestionTypeBigImages extends QuestionBase {
    images: IQuestionBigImages;
    allImageIds: string[];
}

export interface QuestionTypeSlider extends QuestionBase {
    min: number;
    max: number;
    scala: number;
    unit: string;
    continuous: boolean;
}
export interface QuestionTypeContact extends QuestionBase {
    contactFields: QuestionContactFields;
    allContactFieldsIds: string[];
    legalDescription: LegalDescriptionInterface;
    newsletter: string;
    // Indicates if this contact field should have a newsletter and legal description checkboxes
    activateNewsletter?: boolean;
    activateLegalDescription?: boolean;
}

export interface QuestionTypeText extends QuestionBase {
    label: string;
    helperText: string;
}

export interface QuestionTypeThankYou extends QuestionBase {
    link: string;
    isAutoRedirect: boolean;
    hasButton: boolean;
}

export type QuestionTypeMessage = QuestionBase;

export interface QuestionTypeCheckboxes extends QuestionBase {
    checkboxes: QuestionCheckboxes;
    allCheckboxesIds: string[];
}

export interface QuestionTypeCheckToInput extends QuestionBase {
    checkToInputFields: QuestionCheckToInputs;
    allCheckToInputIds: string[];
}

export interface QuestionTypeBubble extends QuestionBase {
    bubble: QuestionBubbles;
    allBubblesIds: string[];
}

export interface QuestionTypeLoading extends QuestionBase {
    loadingType: string;
    duration: number;
    determinate: boolean;
    withLabel: boolean;
    progression: "linear" | "log" | "none";
}

export interface QuestionSelection extends QuestionImage {
    // heading := name of the base interface will act as a heading
    description: string;
    checkFields: { [key: string]: string };
    iconOverrides?: { [key: string]: { color?: string; icon?: string } };
    allCheckFieldIds: string[];
    // undefined -> no border, !undefined -> border with that string
    border?: string;
}

export interface QuestionTypeFirstSelection extends QuestionBase {
    // This interface aims to be compatible to the IQuestionType(Big)Images
    // It will extend the underlying type a bit
    images: { [key: string]: QuestionSelection };
    allImageIds: string[];
}

export enum OptionalValuationQuestions {
    CONSTRUCTION_HOUSE = "CONSTRUCTION_HOUSE",
    CONSTRUCTION_FLAT = "CONSTRUCTION_FLAT",
    CONSTRUCTION_YEAR = "CONSTRUCTION_YEAR",
    LIVING_AREA = "LIVING_AREA",
    PLOT_AREA = "PLOT_AREA",
    ROOMS = "ROOMS",
    FLOOR = "FLOOR",
    FLOOR_NUMBER = "FLOOR_NUMBER",
    ELEVATOR = "ELEVATOR",
    GARAGES = "GARAGES",
    OUTDOOR_PARKING_SPACE = "OUTDOOR_PARKING_SPACE",
    BATH_ROOM = "BATH_ROOM",
    FLOORING = "FLOORING",
    HEATING = "HEATING"
}

export interface QuestionTypeValuation extends QuestionBase {
    loadingType: string;
    // Question Ids
    addressQuestion: string;
    categoryQuestion: string;
    showValuation: boolean;
    optionalQuestions: Record<OptionalValuationQuestions, string | string[] | undefined>;

    // NOTE(luca): Why are these needed
    response: string;
    label: string;
}

export interface QuestionTypeAddress extends QuestionBase {
    // address: string;
    helperText: string;
    label: string;
}

export interface QuestionTypeBubbles extends QuestionBase {
    bubble: QuestionBubbles;
    allBubbleIds: string[];
}

export type Question =
    | QuestionTypeWelcome
    | QuestionTypeIcons
    | QuestionTypeImages
    | QuestionTypeSlider
    | QuestionTypeContact
    | QuestionTypeText
    | QuestionTypeAddress
    | QuestionTypeThankYou
    | QuestionTypeMessage
    | QuestionTypeCheckboxes
    | QuestionTypeValuation
    | QuestionTypeFirstSelection
    | QuestionTypeCheckToInput
    | QuestionTypeBubbles;

export interface ImageContent {
    image: string;
    compatImage: string;
    imageSize: number;
    mobileImageSize: number;
}

export interface ExtraContentBase {
    _id: string;
    type: ExtraContentType;
}

export interface ExtraContentTitle extends ExtraContentBase {
    title: string;
}

export interface ExtraContentDescription extends ExtraContentBase {
    description: string;
}

export interface ExtraContentMessage extends ExtraContentBase {
    message: string;
}

export interface ExtraContentImage extends ExtraContentBase {
    image: ImageContent;
}

export interface ExtraContentButton extends ExtraContentBase {
    button: string;
}

export interface ExtraContentDivider extends ExtraContentBase {
    width: number;
    color: string;
    thickness: number;
    paddingTop: number;
    paddingBottom: number;
}

export type ExtraContent = ExtraContentImage | ExtraContentTitle | ExtraContentDescription | ExtraContentMessage | ExtraContentButton | ExtraContentDivider;

import React, { FC, useState } from "react";
import { Select, MenuItem, FilledInput, Typography, Grid, makeStyles, createStyles } from "@material-ui/core";
import { useLeadGeneratorDispatch } from "../generator-state";
import { QuestionTypeIcons } from "../../smashleads/interfaces/Question";
import { DesignGeneral } from "../../smashleads/interfaces/Design";
import { generateQuestionIds, generateTitle } from "./default-values";

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            textAlign: "center",
            height: 46,

            [theme.breakpoints.down("xs")]: {
                marginLeft: theme.spacing(0),
                marginRight: theme.spacing(0),
                width: "100%"
            }
        },
        label: {
            fontWeight: 600,
            fontSize: "14px",
            marginBottom: 8
        }
    })
);

interface QuestionSelectProps {
    question: QuestionTypeIcons;
    design: DesignGeneral;
    setCurrentQuestions: any;
    categoryValue?: any;
    setCategoryValue?: any;
    hasAddress: string;
    existingValue?: any;
    setExistingValue?: any;
}

const QuestionSelect: FC<QuestionSelectProps> = props => {
    const { question, design, setCurrentQuestions, categoryValue, setCategoryValue, hasAddress, existingValue, setExistingValue } = props;
    const classes = useStyles(design);
    const dispatch = useLeadGeneratorDispatch();

    const [selectValue, setSelectValue] = useState("default");
    const handleSelectChange = async e => {
        const generatedValue = (question as any).restId === "category" ? e.target.value : categoryValue;
        const generatedExistingValue = (question as any).restId === "existingRealEstate" ? e.target.value : existingValue;
        const questions = generateQuestionIds(generatedValue, hasAddress, generatedExistingValue);
        setCurrentQuestions(questions);
        setSelectValue(e.target.value);
        (question as any).restId === "category" && setCategoryValue(e.target.value);
        (question as any).restId === "existingRealEstate" && setExistingValue(e.target.value);

        await dispatch({
            type: "updateQA",
            question: {
                questionId: question._id,
                restId: (question as any).restId,
                question: question.title === "" ? question.description : question.title,
                answer: e.target.value !== "default" ? question.icons[e.target.value].name : ""
            }
        });
    };

    return (
        <Grid item xs={6}>
            <Typography align="left" className={classes.label}>
                {generateTitle(question as any)}
            </Typography>
            <Select value={selectValue} onChange={handleSelectChange} variant={"outlined"} input={<FilledInput className={classes.root} />}>
                <MenuItem value={"default"} disabled hidden>
                    Wählen Sie einen Entwurf aus
                </MenuItem>
                {question.allIconIds.map((key: string, index: number) => (
                    <MenuItem key={key} value={question.icons[key]._id}>
                        {question.icons[key].name}
                    </MenuItem>
                ))}
            </Select>
        </Grid>
    );
};

export default QuestionSelect;

import * as React from "react";

const SvgKauf = (props: React.SVGProps<SVGSVGElement>) => (
    <svg id="Kauf_svg__GlobaleEbene" viewBox="0 0 1200 1200" width={props.width} height={props.height} {...props}>
        <g id="Kauf_svg__PrimaryColorElements">
            <path fill="#dedede" d="M284.81 285.45l592.16 153.47v759.77H289.05l-4.24-913.24z" />
            <path fill="#e8e8e8" d="M904.6 1198.69H316.68V500.25l590.45-123.71v115.62l-2.53 706.53" />
            <path fill="#bcbcbc" d="M230.47 1175.07h730.11v23.62H230.47z" />
            <path
                fill="#fff"
                d="M447.84 575.22h147.68v179.76H447.84zM637.77 575.22h147.68v179.76H637.77zM637.77 959.64h82.62v91.22h-82.62zM733.67 1006.68h71.24v78.66h-71.24z"
            />
            <path
                fill="#f2f2f2"
                d="M447.84 575.23l116.35 179.76H447.84V575.23zM637.77 575.23l116.35 179.76H637.77V575.23z"
            />
        </g>
        <g id="Kauf_svg__BaseElements" fill={props.color}>
            <path d="M424.27 946.83h156.62v227.72H424.27zM1020.34 745.44c-14.51-2.57-24.53-15.57-22.79-29.58 2.14-14.1 15.81-23.73 30.54-21.49s24.91 15.48 22.79 29.63c-2.49 13.85-15.88 23.29-30.54 21.44zm-13.75-89.17c-53.87-7.7-103.68 27.36-112 78.8a113.33 113.33 0 005.21 49.56L743.05 895l-5.17 34L809 939.79l5.17-34 35.55 5.39 5.17-34 35.55 5.4 65.16-45.85a108.87 108.87 0 0022.59 6.91c53.87 7.7 103.68-27.35 112-78.8 7.3-51.69-29.87-99.94-83.6-108.57z" />
        </g>
    </svg>
);

export default SvgKauf;

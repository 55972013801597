import * as React from "react";

const SvgImBau = (props: React.SVGProps<SVGSVGElement>) => (
    <svg id="ImBau_svg__GlobaleEbene" viewBox="0 0 1200 1200.02" width={props.width} height={props.height} {...props}>
        <g id="ImBau_svg__BaseElements">
            <path fill="#e8e8e8" d="M825.58 1141.55h-494.7v-402.1l494.7.04v402.06z" />
            <path fill="#e8e8e8" d="M330.88 1141.55H41.96V804.66h289.58l-.66 336.89z" />
            <path
                fill="#f9f9f9"
                d="M365.69 685.03h253.15v115H365.69zM78.6 839.34h253.15v115H78.6zM538.48 839.34h253.15v115H538.48zM164.56 989.02h253.15v115H164.56zM450.77 989.02h253.15v115H450.77zM365.74 839.34h138.75v115H365.74zM78.6 989.02h52.01v115H78.6zM739.62 989.02h52.01v115h-52.01z"
            />
            <path
                fill="#f2f2f2"
                d="M223.27 839.34l85.94 115.01h-85.94L142.8 839.34h80.47zM591.45 989.02l85.93 115.01h-85.93l-80.47-115.01h80.47zM519.79 681.99L605.73 797h-85.94l-80.47-115.01h80.47z"
            />
            <path fill="#ddd" d="M595.68 854.04h138.75v115H595.68zM124.7 637.98h166.68v166.68H124.7z" />
        </g>
        <g id="ImBau_svg__PrimaryColorElements">
            <path fill={props.color} d="M1025.04 365.07h128.93v41.6h-128.93zM1025.04 416.12h128.93v41.6h-128.93z" />
            <path
                fill="none"
                stroke={props.color}
                strokeMiterlimit={10}
                strokeWidth={16}
                d="M642.79 398.53l316.7-116.95 114.39 89.87"
            />
            <path fill={props.color} d="M1018.25 380.75h-697.9L257 432.6h761.25" />
            <path
                fill="none"
                stroke={props.color}
                strokeLinejoin="bevel"
                strokeWidth={16}
                d="M867.12 404.95l86.36-123.37v125.15"
            />
            <path fill={props.color} d="M862.85 430.44h86.36V1181h-86.36z" />
            <path
                fill={props.color}
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="bevel"
                strokeWidth={11}
                d="M949.21 438.34l-86.36 69.74 86.36 69.61-86.36 60.89 86.36 60.88"
            />
            <path
                fill={props.color}
                stroke="#fff"
                strokeLinejoin="bevel"
                strokeWidth={11}
                d="M949.21 697.91l-86.36 68.49 86.36 68.49-86.36 60.01 86.36 59.9"
            />
            <path
                fill="none"
                stroke={props.color}
                strokeMiterlimit={10}
                strokeWidth={7}
                d="M707.17 423.32l-43.18 222.92-43.18-231.57"
            />
            <rect
                x={663.99}
                y={646.24}
                height={207.8}
                fill="none"
                stroke={props.color}
                strokeMiterlimit={10}
                strokeWidth={7}
            />
            <path
                fill={props.color}
                stroke="#fff"
                strokeLinejoin="bevel"
                strokeWidth={11}
                d="M951.55 956.02l-86.36 68.49 86.36 68.49-86.36 60.01 61.31 42.49"
            />
            <path
                fill={props.color}
                stroke={props.color}
                strokeMiterlimit={10}
                strokeWidth={42}
                d="M1048 1177.46H766"
            />
        </g>
    </svg>
);

export default SvgImBau;

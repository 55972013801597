import * as React from "react";

const SvgWohnung = (props: React.SVGProps<SVGSVGElement>) => (
    <svg id="Wohnung_svg__GlobaleEbene" viewBox="0 0 1202 1202" width={props.width} height={props.height} {...props}>
        <g id="Wohnung_svg__BaseElements">
            <path fill="#e8e8e8" d="M1024.09 1181H486.1V362.19l537.99 123.58V1181z" />
            <path fill="#e8e8e8" d="M486.1 1181H171.91V814.64h314.91L486.1 1181z" />
            <path
                fill="#f9f9f9"
                d="M523.97 526.79h275.3v125.07h-275.3zM211.75 852.35h275.3v125.07h-275.3zM711.87 852.35h275.3v125.07h-275.3zM305.23 1015.13h275.3v125.07h-275.3zM616.48 1015.13h275.3v125.07h-275.3zM838.88 526.79h150.89v125.07H838.88zM524.01 852.35H674.9v125.07H524.01zM211.75 1015.13h56.56v125.07h-56.56zM930.61 1015.13h56.56v125.07h-56.56z"
            />
            <path
                fill="#f2f2f2"
                d="M369.07 852.35l93.46 125.07h-93.46l-87.51-125.07h87.51zM769.47 1015.13l93.46 125.07h-93.46l-87.51-125.07h87.51zM678.99 526.79l93.45 125.07h-93.45l-87.52-125.07h87.52z"
            />
            <path fill="#bcbcbc" d="M118.46 1181h969v19.38h-969z" />
        </g>
        <g id="Wohnung_svg__PrimaryColorElements" fill={props.color}>
            <path d="M714.47 689.57h275.3v125.07h-275.3zM523.97 689.57h157.99v125.07H523.97z" />
        </g>
    </svg>
);

export default SvgWohnung;

import {
    Checkbox,
    createStyles,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    hexToRgb,
    makeStyles,
    Theme,
    Typography,
    useMediaQuery,
    withStyles
} from "@material-ui/core";
import { AsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { useRouter } from "next/router";
import React, { FC, Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { DesignGeneral } from "../../smashleads/interfaces/Design";
import { ContactType, QuestionTypeContact } from "../../smashleads/interfaces/Question";
import { LeadContact, useLeadGeneratorDispatch, useLeadGeneratorState } from "../generator-state";
import LegalNote from "../helpers/legal-note";
import NewsletterText from "../helpers/newsletter-text";
import PageNextButton from "../page-next-button";
import { HandleForwardType } from "../types";
import { parseQuiz } from "./prepare-lead";
import { ContactDocument } from "@onpreo/database";
import StyledContactTextField from "../helpers/styled-contact-textfield";
import { ControlledSwitch } from "@onpreo/components";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1
        },
        container: {
            padding: theme.spacing(0),
            marginBottom: theme.spacing(2)
        },
        checkboxWrapper: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        textRow: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            width: "50%",
            [theme.breakpoints.down("xs")]: {
                marginLeft: theme.spacing(0),
                marginRight: theme.spacing(0),
                width: "100%"
            },
            display: "block"
        }
    })
);

const SlError = withStyles(theme => ({
    root: {
        textAlign: "left",
        fontSize: "0.8rem",
        color: theme.palette.error.main
    }
}))(Typography);

interface QuestionContactProps {
    question: QuestionTypeContact;
    design: DesignGeneral;
    handleForward: HandleForwardType;
    handleBackward(): void;
    onCreate?: AsyncThunk<any, any, {}>;
    hasValuation?: "enabled" | "disabled" | boolean;
    legalObligations?: any;
    defaultContact?: any;
    onCheckContactExists?: (email: string) => Promise<{ email: string; name: string; phone: string }>;
    preOwner?: { name: string; email: string; phone: string };
    dispatchFlag?: boolean;
}

interface FormFields extends LeadContact {
    form: string;
}

export const localePageContact = {
    en: {
        invalid_input: "Invalid input",
        invalid: "Invalid",
        required: "Required",
        input_too_long: "Input too long",
        input_too_short: "Input too small",
        agreement_required: "You have to accept the terms of use and the privacy policy in order to continue",
        try_again: "Try again later"
    },
    de: {
        invalid_input: "Eingabe ungültig!",
        invalid: "Ungültige",
        required: "Benötigt",
        input_too_long: "Eingabe zu lang",
        input_too_short: "Eingabe zu kurz",
        agreement_required: "Sie müssen den Nutzungsbedingungen und der Datenschutzerklärung zustimmen",
        try_again: "Versuchen Sie es später nochmal"
    }
};

export const contactTypeToField = (type: ContactType) => {
    switch (type) {
        case ContactType.FULL_NAME:
            return "fullName";
        case ContactType.FIRST_NAME:
            return "firstName";
        case ContactType.LAST_NAME:
            return "lastName";
        case ContactType.EMAIL:
            return "email";
        case ContactType.PHONE:
            return "phone";
        case ContactType.DATE:
            return "date";
        case ContactType.STREET:
            return "street";
        case ContactType.POSTAL_CODE:
            return "postalCode";
        case ContactType.CITY:
            return "city";
        case ContactType.COUNTRY:
            return "country";
        case ContactType.COMPANY:
            return "company";
        case ContactType.WEBSITE:
            return "website";
        case ContactType.SECTOR:
            return "sector";
        case ContactType.CUSTOM:
            return "custom";
        default:
            return "Nothing selected";
    }
};

export const getCustomFieldName = (text: string) => {
    return text.replace(/[^a-zA-Z0-9 ]/g, "");
};

const QuestionContact: FC<QuestionContactProps> = props => {
    const {
        question,
        design,
        handleForward,
        handleBackward,
        onCreate,
        hasValuation,
        legalObligations,
        defaultContact,
        onCheckContactExists,
        preOwner,
        dispatchFlag
    } = props;
    const classes = useStyles();
    const [loading, setLoading] = useState<boolean>(false);
    const [saveFailed, setSaveFailed] = useState<boolean>(false);
    const {
        control,
        formState: { errors },
        handleSubmit,
        setError,
        watch,
        setValue,
        getValues
    } = useForm({ mode: "onChange" });
    const [disabled, setDisabled] = useState<boolean>(true);
    const state = useLeadGeneratorState();
    const dispatch = useLeadGeneratorDispatch();
    const isMobile = useMediaQuery("(max-width:600px)");
    const thunkDispatch = useDispatch();

    const newsletterActivated = !!question.activateNewsletter;
    const legalDescriptionActivated = !!question.activateLegalDescription;
    const hideBackward = !design.contact.displayBackButton;

    const locale = localePageContact["de"];
    const router = useRouter();
    const { realEstateId, cid } = router?.query;

    const isPhoneRequired =
        question.allContactFieldsIds.filter(id => question.contactFields[id].type === ContactType.PHONE && question.contactFields[id].required).length > 0;

    const [show, setShow] = useState(undefined);

    const onSubmit = async (data: FormFields | any) => {
        if (data.form) return setError("form", { type: "validation" });
        if (Object.keys(errors).length === 0) {
            data.origin = state.origin;

            dispatch({ type: "updateContact", contact: data });
            if (hasValuation === "disabled" && defaultContact && !dispatchFlag) {
                const lead = parseQuiz(state);
                await thunkDispatch(onCreate({ lead, realEstateId, data }));
            }
            handleForward(question.target);
        }
    };

    useEffect(() => {
        const { unsubscribe } = watch(values => {
            delete values.form;
            const checkedValues = Object.keys(values).filter(val => !!values[val]);
            let reqFieldsProvided = true;
            for (const id of question.allContactFieldsIds) {
                const { helperText, required, type } = question.contactFields[id];
                if (!values["isReadAgreementChecked"] && legalDescriptionActivated) {
                    reqFieldsProvided = false;
                    break;
                }
                if (required) {
                    reqFieldsProvided =
                        type === ContactType.CUSTOM
                            ? checkedValues.includes(getCustomFieldName(helperText))
                            : checkedValues.includes(contactTypeToField(type));
                    if (!reqFieldsProvided) break;
                }
            }

            if (watch("noNeedOwner")) {
                setDisabled(false);
            } else {
                setDisabled(checkedValues.length === 0 || !reqFieldsProvided || Object.keys(errors).length !== 0 || loading);
            }
        });
        return () => unsubscribe();
    }, [watch, errors]);

    useEffect(() => {
        if (defaultContact) {
            const [firstName, lastName] = defaultContact?.meta?.name?.split(" ");
            setValue("firstName", firstName, { shouldValidate: true });
            setValue("lastName", lastName, { shouldValidate: true });
            setValue("email", defaultContact?.meta?.email, { shouldValidate: true });
            setValue("phone", defaultContact?.meta?.phone, { shouldValidate: true });
        }
    }, []);

    useEffect(() => {
        if (show)
            (async () => {
                const [firstName, lastName] = show?.name?.split(" ");
                setValue("firstName", firstName, { shouldValidate: true });
                setValue("lastName", lastName, { shouldValidate: true });
                setValue("email", show?.email, { shouldValidate: true });
                setValue("phone", show?.phone, { shouldValidate: true });
            })();
    }, [show]);

    useEffect(() => {
        if (preOwner)
            (async () => {
                const [firstName, lastName] = preOwner?.name?.split(" ");
                setValue("firstName", firstName, { shouldValidate: true });
                setValue("lastName", lastName ?? " ", { shouldValidate: true });
                setValue("email", preOwner?.email, { shouldValidate: true });
                setValue("phone", preOwner?.phone, { shouldValidate: true });
            })();
    }, [preOwner]);

    const checkExists = async (value: string) => {
        if (value) {
            const existingDoc = await onCheckContactExists(value);
            if (existingDoc?.email === value) setShow(existingDoc);
        }
    };

    return (
        <div className={classes.root}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container direction={"row"} justifyContent={"center"}>
                    {!preOwner && (
                        <Grid item xs={12} container justifyContent={"center"} style={{ margin: watch("noNeedOwner") ? "8px 8px 210px" : "8px 8px 16px" }}>
                            <ControlledSwitch control={control} name={"noNeedOwner"} />
                            <Typography variant={"body2"} style={{ paddingLeft: 10 }}>
                                Ich habe keine Eigentümerinformationen
                            </Typography>
                        </Grid>
                    )}

                    {!watch("noNeedOwner") && (
                        <Fragment>
                            <Grid justifyContent={"space-between"} alignItems={"center"} className={classes.container} container spacing={1}>
                                {question.allContactFieldsIds.map(id => {
                                    const { helperText, required, type } = question.contactFields[id];
                                    const fieldName = type === ContactType.CUSTOM ? getCustomFieldName(helperText) : contactTypeToField(type);
                                    let errorMessage = undefined;
                                    if (errors[fieldName]) errorMessage = errors[fieldName].message;
                                    let inputType = undefined;

                                    const len = question.allContactFieldsIds.length;
                                    let pos: any = 12;
                                    if (len > 4 && len <= 8) pos = 6;
                                    if (len > 8) pos = 4;

                                    let pattern = undefined;
                                    if (type === ContactType.PHONE) {
                                        inputType = "tel";
                                        pattern = {
                                            value: /^undefined$|^[0-9+() -]*$/,
                                            message: `${locale.invalid} ${helperText}`
                                        };
                                    } else if (type === ContactType.EMAIL) {
                                        inputType = "email";
                                        pattern = {
                                            value: /^undefined$|^(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i,
                                            message: `${locale.invalid} ${helperText}`
                                        };
                                    } else if (type === ContactType.POSTAL_CODE)
                                        pattern = {
                                            value: /^undefined$|^[0-9 ]*$/,
                                            message: `${locale.invalid} ${helperText}`
                                        };

                                    return (
                                        <Grid item key={type} xs={12} sm={6}>
                                            <Controller
                                                key={type}
                                                name={fieldName}
                                                render={({ field }) => (
                                                    <StyledContactTextField
                                                        error={!!errors[fieldName]}
                                                        variant={design.textField.variant as any}
                                                        name={helperText}
                                                        label={`${helperText} ${!required ? "(optional)" : ""}`}
                                                        id={helperText}
                                                        design={design.textField}
                                                        helperText={errorMessage}
                                                        type={inputType}
                                                        checkExists={checkExists}
                                                        full={true}
                                                        disabled={!!preOwner}
                                                        {...field}
                                                    />
                                                )}
                                                control={control}
                                                rules={{
                                                    required: { value: required, message: locale.required },
                                                    minLength: { value: 1, message: locale.input_too_short },
                                                    maxLength: { value: 64, message: locale.input_too_long },
                                                    pattern: pattern
                                                }}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                            {show && (
                                <Grid container justifyContent={"center"}>
                                    <Typography style={{ fontSize: 14 }}>
                                        Diese E-Mail-Adresse liegt bereits in Ihrem System vor. Die hier erstellte Immobilie wird dem im System hinterlegten
                                        Kontakt zugewiesen. Falls Sie für diese Immobilie einen neuen Eigentümer erstellen möchten, geben Sie bitte eine
                                        neue, eindeutige E-Mail-Adresse an.
                                    </Typography>
                                </Grid>
                            )}
                        </Fragment>
                    )}

                    <Grid container direction={"row"} justify={"center"} alignItems={"center"} spacing={2}>
                        <Grid item xs={12} className={classes.checkboxWrapper}>
                            <Grid container alignItems={"center"} justify={"flex-start"} className={classes.textRow}>
                                {newsletterActivated && (
                                    <FormControl style={{ flexBasis: "100%" }}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Controller
                                                        name="isNewsletterChecked"
                                                        control={control}
                                                        defaultValue={false}
                                                        render={({ field: { onBlur, onChange, value } }) => (
                                                            <Checkbox
                                                                id={"newsletter-accepted"}
                                                                checked={value}
                                                                onChange={e => onChange(e.target.checked)}
                                                                style={{
                                                                    color: design.buttons.backgroundColor
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                }
                                                label={<NewsletterText design={design} newsletter={props.question.newsletter} />}
                                            />
                                        </FormGroup>
                                    </FormControl>
                                )}
                                {legalDescriptionActivated && (
                                    <FormControl style={{ flexBasis: "100%" }}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Controller
                                                        name="isReadAgreementChecked"
                                                        control={control}
                                                        defaultValue={false}
                                                        render={({ field: { onBlur, onChange, value } }) => (
                                                            <Checkbox
                                                                id={"read-agreement"}
                                                                checked={value}
                                                                onChange={e => onChange(e.target.checked)}
                                                                style={{
                                                                    color: !!errors.isReadAgreementChecked
                                                                        ? hexToRgb("#FF006C")
                                                                        : design.buttons.backgroundColor
                                                                }}
                                                            />
                                                        )}
                                                        rules={{ required: true }}
                                                    />
                                                }
                                                label={
                                                    <LegalNote
                                                        legalObligations={legalObligations}
                                                        design={design}
                                                        legalDescription={question.legalDescription}
                                                    />
                                                }
                                            />
                                            <FormHelperText style={{ marginLeft: 30 }}>
                                                {!!errors.isReadAgreementChecked && <SlError>{locale.agreement_required}</SlError>}
                                            </FormHelperText>
                                        </FormGroup>
                                    </FormControl>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item container justify={"center"}>
                            <PageNextButton
                                value={question.button}
                                design={design}
                                hideBackward={hideBackward}
                                isMobile={isMobile}
                                handleForward={() => {}}
                                handleBack={handleBackward}
                                contentName={question.title}
                                loading={loading}
                                disabled={disabled}
                                type={"submit"}
                            />
                        </Grid>
                        {/* <Controller style={{ display: "none" }} name={"form"} as={<TextField defaultValue={""} />} control={control} /> */}
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};

export default QuestionContact;

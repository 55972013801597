import React, { FC, useEffect } from "react";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { FilledInput } from "@onpreo/components";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import { Autocomplete } from "@material-ui/lab";
import { createStyles, TextField } from "@material-ui/core";
import { AddressAnswer, Question } from "../types";
import { QuestionTypeAddress } from "../../smashleads/interfaces/Question";
import useScript from "./use-script";
import { DesignGeneral } from "../../smashleads/interfaces/Design";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const autocompleteService: { current?: google.maps.places.AutocompleteService } = { current: undefined };

const useStyles = makeStyles(muiTheme =>
    createStyles({
        icon: {
            color: muiTheme.palette.text.secondary,
            marginRight: muiTheme.spacing(2)
        },
        searchIcon: {
            width: 20,
            height: 20
        },
        autocomplete: {
            display: "flex",
            justifyContent: "center"
        },
        inputField: {
            width: "85%",
            display: "flex",
            justifyContent: "flex-start",
            padding: "0 8px 0 0",
            [muiTheme.breakpoints.down("md")]: {
                width: "90%"
            },
            [muiTheme.breakpoints.down("sm")]: {
                width: "75%"
            },
            [muiTheme.breakpoints.down("xs")]: {
                width: "100%"
            }
        }
    })
);

interface PlacesAutocomplete {
    question: QuestionTypeAddress;
    design: DesignGeneral;
    value: AddressAnswer | null;
    onChange(value: AddressAnswer | null): void;
    envVar?: string;
}

const localePlacesAutocomplete = {
    en: {
        inputAddress: "Input your address",
        helperText: "Please add your house number",
        noAddress: "Could not find the address",
        zipCode: "Zip Code",
        city: "City",
        country: "Country",
        street: "Address",
        houseNumber: "Nr.",
        requiredField: "Required"
    },
    de: {
        inputAddress: "Adresse eingeben",
        helperText: "Die Hausnummer muss angegeben werden!",
        noAddress: "Keine Adresse gefunden",
        zipCode: "PLZ",
        city: "Ort",
        country: "Land",
        street: "Adresse",
        houseNumber: "Hausnr.",
        requiredField: "Dies ist ein Pflichtfeld"
    }
};

const noAddressOption = { description: "Ihre Adresse nicht dabei? Jetzt manuell hinzufügen...", placeId: "noAddress", hasHouseNumber: false };

const PlacesAutocomplete: FC<PlacesAutocomplete> = ({ question, design, value, onChange: setValue, envVar }) => {
    const classes = useStyles();
    const [inputValue, setInputValue] = React.useState("");
    const [options, setOptions] = React.useState<AddressAnswer[]>([]);
    const locale = localePlacesAutocomplete["de"];
    const componentRestrictions = { country: ["de", "ch", "at", "hu"] };

    // const loaded = React.useRef(false);
    const fetch = React.useMemo(
        () =>
            throttle(
                (
                    request: google.maps.places.AutocompletionRequest,
                    callback: (result: google.maps.places.AutocompletePrediction[] | null, status: google.maps.places.PlacesServiceStatus) => void
                ) => {
                    if (autocompleteService.current !== undefined) autocompleteService.current.getPlacePredictions(request, callback);
                },
                200
            ),
        []
    );

    const scriptSrc = `https://maps.googleapis.com/maps/api/js?key=${envVar}&libraries=places`;
    const status = useScript(scriptSrc);

    useEffect(() => {
        let active = true;
        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }
        if (inputValue === "") {
            setOptions(value ? [value] : []);
            return undefined;
        }

        try {
            fetch({ input: inputValue, types: ["address"], componentRestrictions }, results => {
                if (active) {
                    const mappedResults =
                        results !== null
                            ? results.map(
                                  r =>
                                      ({
                                          description: r.description,
                                          placeId: r.place_id,
                                          structured_formatting: r.structured_formatting,
                                          hasHouseNumber: false
                                      } as AddressAnswer)
                              )
                            : null;
                    let newOptions: AddressAnswer[] = [];
                    if (value) newOptions = [value];
                    if (mappedResults) newOptions = [...newOptions, ...mappedResults, noAddressOption];
                    setOptions(newOptions);
                }
            });
        } catch (err) {
            console.log(err);
            console.info("Please remove any add-ons that might block services");
        }

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch, status]);

    const updateValue = (value: AddressAnswer | null) => {
        if (!!value?.description) {
            // Check if a house number is provided
            if (value?.description?.match(/^\D+\d+\D+$/)) value.hasHouseNumber = true;
        }

        setValue(value);
    };

    return (
        <Autocomplete
            id="google-map"
            className={classes.autocomplete}
            getOptionLabel={option => (typeof option === "string" ? option : option.description)}
            filterOptions={x => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            noOptionsText={locale.noAddress}
            onChange={(event, newValue) => {
                setOptions(newValue ? ([newValue, ...options] as AddressAnswer[]) : options);
                updateValue(newValue as AddressAnswer);
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={params => (
                <div style={{ width: "100%" }}>
                    <FilledInput
                        ref={params.InputProps.ref}
                        // className={classes.inputField}
                        variant={"filled"}
                        {...params}
                        label={question.label || locale.inputAddress}
                        // @ts-ignore
                        error={value && !value.hasHouseNumber ? { message: locale.helperText } : undefined}
                    />
                </div>
            )}
            renderOption={option => {
                const matches = option?.structured_formatting?.main_text_matched_substrings;
                const parts =
                    matches &&
                    parse(
                        option?.structured_formatting?.main_text,
                        matches?.map(match => [match?.offset, match?.offset + match?.length])
                    );

                return (
                    <Grid container alignItems="center">
                        {matches && (
                            <>
                                <Grid item>
                                    <LocationOnIcon className={classes.icon} />
                                </Grid>
                                <Grid item xs>
                                    {parts.map((part, index) => (
                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                            {part.text}
                                        </span>
                                    ))}

                                    <Typography variant="body2" color="textSecondary">
                                        {option.structured_formatting?.secondary_text}
                                    </Typography>
                                </Grid>
                            </>
                        )}
                        {!matches && (
                            <>
                                <Grid item>
                                    <HelpOutlineIcon className={classes.icon} />
                                </Grid>
                                <Grid item xs>
                                    <span style={{ fontSize: 14 }}>{option?.description}</span>
                                </Grid>
                            </>
                        )}
                    </Grid>
                );
            }}
        />
    );
};

export default PlacesAutocomplete;

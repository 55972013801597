import { createStyles, Grid, Theme, Typography, makeStyles, Select, MenuItem, FilledInput } from "@material-ui/core";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useLeadGeneratorDispatch, useLeadGeneratorState } from "../generator-state";
import { QuestionTypeSlider } from "../../smashleads/interfaces/Question";
import { DesignGeneral } from "../../smashleads/interfaces/Design";
import StyledTextField from "../helpers/styled-textfield";
import { generateTitle } from "./default-values";

const useStyles = makeStyles((muiTheme: Theme) =>
    createStyles({
        sliderTextField: {
            width: "100%",
            minWidth: 144,
            marginLeft: muiTheme.spacing(2),
            marginRight: muiTheme.spacing(2),
            [muiTheme.breakpoints.down("sm")]: {
                marginLeft: muiTheme.spacing(0),
                marginRight: muiTheme.spacing(0)
            }
        }
    })
);

interface QuestionFieldValueProps {
    question: QuestionTypeSlider;
    design: DesignGeneral;
    lang: "en" | "de";
    yearList?: string[];
}

const localePageSlider = {
    en: {
        no_input: "No input"
    },
    de: {
        no_input: "Keine Angabe"
    }
};

const QuestionFieldValue: FC<QuestionFieldValueProps> = props => {
    const { question, design, lang = "en", yearList = undefined } = props;
    const classes = useStyles(design);
    const { unit } = question;
    const state = useLeadGeneratorState();
    const dispatch = useLeadGeneratorDispatch();
    const locale = localePageSlider["de"];

    const [textValue, setTextValue] = useState("");
    const [selectValue, setSelectValue] = useState("default");

    useEffect(() => {
        setTextValue(
            state.questions[question._id] && state.questions[question._id].answer && state.questions[question._id].answer !== ""
                ? (state.questions[question._id].answer as string)
                : ""
        );
    }, [question]);

    const onTextFieldChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        const parsed = parseInt(newValue);
        if ((textValue === "" && newValue !== "" && !isNaN(parsed)) || (textValue !== "" && newValue === "")) {
            await dispatch({
                type: "updateQA",
                question: {
                    questionId: question._id,
                    restId: (question as any).restId,
                    question: question.title === "" ? question.description : question.title,
                    answer: newValue === "" ? locale.no_input : newValue
                }
            });
        }

        if (newValue === "") {
            setTextValue(newValue);
        }
        if (!isNaN(parsed)) {
            setTextValue(newValue);
        }
    };

    function handleCommit() {
        dispatch({
            type: "updateQA",
            question: {
                questionId: question._id,
                question: question.title === "" ? question.description : question.title,
                restId: (question as any).restId,
                answer: textValue === "" ? locale.no_input : textValue
            }
        });
    }

    const handleSelectChange = event => {
        const newValue = event.target.value;

        dispatch({
            type: "updateQA",
            question: {
                questionId: question._id,
                restId: (question as any).restId,
                question: question.title === "" ? question.description : question.title,
                answer: newValue === "default" ? locale.no_input : String(newValue)
            }
        });
        setSelectValue(newValue);
    };

    return (
        <Grid item xs={6}>
            <Typography align="left" style={{ fontWeight: 600, fontSize: "14px" }}>
                {generateTitle(question as any)}
            </Typography>

            {yearList ? (
                <Select
                    value={selectValue}
                    onChange={handleSelectChange}
                    variant={"outlined"}
                    input={<FilledInput />}
                    style={{ marginTop: 8, textAlign: "left", height: 46 }}
                >
                    <MenuItem value={"default"} disabled hidden>
                        {locale.no_input}
                    </MenuItem>
                    {yearList.map((item, key) => {
                        return (
                            <MenuItem key={key} value={item}>
                                {item}
                            </MenuItem>
                        );
                    })}
                </Select>
            ) : (
                <StyledTextField
                    className={classes.sliderTextField}
                    error={false}
                    design={design.textField}
                    variant={"outlined"}
                    value={textValue}
                    placeholder={locale.no_input}
                    onBlur={handleCommit}
                    type={"number"}
                    inputProps={{
                        inputmode: "numeric",
                        pattern: "[0-9]+([.,][0-9]+)?",
                        lang: "de-DE",
                        step: question.scala,
                        min: question.min,
                        max: question.max
                    }}
                    InputProps={{
                        endAdornment: <span>{unit}</span>
                    }}
                    onChange={onTextFieldChange}
                />
            )}
        </Grid>
    );
};

export default QuestionFieldValue;

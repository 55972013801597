import { OptionalValuationQuestions } from "../../smashleads/interfaces/Question";
import { ValuationCategory } from "../sprengnetter";

export const validateCategory = (answer: string): ValuationCategory | undefined => {
    switch (answer) {
        case "Eigentumswohnung":
            return "ETW";
        case "Wohnung":
            return "ETW";
        case "Einfamilienhaus":
            return "EFH";
        case "Einfamilienwohnhaus":
            return "EFH";
        case "Mehrfamilienhaus":
            return "MFH";
        case "Grundstück":
            return "GRD";
        default:
            return undefined;
    }
};

const clamp = (answer: string, lower: number, upper: number): number | undefined => {
    const parsed = parseInt(answer);
    return isNaN(parsed) ? undefined : Math.min(Math.max(parsed, lower), upper);
};

const answerToBool = (answer: string): boolean | undefined => {
    switch (answer) {
        case "Ja":
            return true;
        case "Nein":
            return false;
        default:
            return undefined;
    }
};

// This is the business logic behind the optional questions, this converts what we get from the user to a
// Taken from https://app.swaggerhub.com/apis-docs/SprengnetterAVM/avm/1.0.0#/valuation_request
export const validateOptionalQuestion = (
    type: OptionalValuationQuestions,
    answer: string,
    category: ValuationCategory
): boolean | string | number | undefined => {
    switch (type) {
        case OptionalValuationQuestions.CONSTRUCTION_HOUSE:
            if (!(category === "EFH" || category === "MFH")) return undefined;
            switch (answer) {
                case "Doppelhaushälfte":
                    return "DOPPELHAUS";
                case "Freistehend":
                    return "FREISTEHEND";
                case "Reihen Endhaus":
                case "Reihenendhaus":
                    return "REIHEN_ENDHAUS";
                case "Reihen Mittelhaus":
                case "Reihenmittelhaus":
                case "Reihenhaus":
                    return "REIHEN_MITTELHAUS";
                default:
                    return undefined;
            }
        case OptionalValuationQuestions.CONSTRUCTION_FLAT:
            if (category !== "ETW") return undefined;
            switch (answer) {
                case "Loft":
                    return "LOFT";
                case "Penthouse":
                    return "PENTHOUSE";
                case "Souterrain":
                    return "SOUTERRAIN";
                case "Erdgeschosswohnung":
                case "Erdgeschoss":
                    return "ERDGESCHOSSWOHNUNG";
                case "Etagenwohnung":
                    return "ETAGENWOHNUNG";
                case "Maisonette":
                    return "MAISONETTE";
                case "Terassenwohnung":
                    return "TERRASSENWOHNUNG";
                case "Dachgeschoss":
                case "Dachgeschosswohnung":
                    return "DACHGESCHOSS";
                default:
                    return undefined;
            }
        case OptionalValuationQuestions.CONSTRUCTION_YEAR:
            return clamp(answer, 1800, 2021);
        case OptionalValuationQuestions.LIVING_AREA:
            return category === "GRD" ? undefined : clamp(answer, 0, category === "ETW" ? 500 : 1000);
        case OptionalValuationQuestions.PLOT_AREA:
            return category === "ETW" ? undefined : clamp(answer, 0, 5000);
        case OptionalValuationQuestions.ROOMS:
            return category === "GRD" ? undefined : clamp(answer, 0, 100);
        case OptionalValuationQuestions.FLOOR:
            return category === "ETW" ? clamp(answer, 0, 100) : undefined;
        case OptionalValuationQuestions.FLOOR_NUMBER:
            return category === "EFH" || category === "MFH" ? clamp(answer, 0, 1000) : undefined;
        case OptionalValuationQuestions.ELEVATOR:
            return category === "ETW" ? answerToBool(answer) : undefined;
        case OptionalValuationQuestions.GARAGES:
        case OptionalValuationQuestions.OUTDOOR_PARKING_SPACE:
            return category === "GRD" ? undefined : answerToBool(answer);
        case OptionalValuationQuestions.BATH_ROOM:
            switch (answer) {
                case "Mehr als 1 Bad":
                    return category === "GRD" ? undefined : "MEHR_ALS_EIN_BAD";
                case "1 Bad":
                    return category === "EFH" || category === "MFH" ? "EIN_BAD" : undefined;
            }
        case OptionalValuationQuestions.FLOORING:
            if (category !== "ETW") return undefined;
            switch (answer) {
                case "Fliesen":
                    return "FLIESEN";
                case "Kunststoff oder PVC":
                case "Kunstoff":
                case "PVC":
                    return "KUNSTSTOFF_PVC";
                case "Parkett oder Naturstein":
                case "Parkett":
                case "Naturstein":
                    return "PARKETT_NATURSTEIN";
                case "Teppich oder Laminat":
                case "Teppich":
                case "Laminat":
                    return "TEPPICH_LAMINAT";
                case "Sonstige":
                    return "SONSTIGE";
                default:
                    return undefined;
            }
        case OptionalValuationQuestions.HEATING:
            if (category === "GRD") return undefined;
            switch (answer) {
                case "Fußbodenheizung":
                    return "FUSSBODENHEIZUNG";
                case "Einzelofen":
                    return "EINZELOEFEN";
                case "Zentralheizung":
                    return "ZENTRALHEIZUNG";
                case "Sonstige":
                    return "SONSTIGE";
                default:
                    return undefined;
            }
    }
};

import React, { FC, useEffect, useState } from "react";
import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import StyledTextField from "../helpers/styled-textfield";
import { HandleForwardType, useLeadGeneratorState, useLeadGeneratorDispatch } from "@onpreo/forms";
import { QuestionTypeText, DesignGeneral } from "../../smashleads";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            minHeight: 200,
            maxHeight: 400,
            padding: theme.spacing(0),
            marginTop: "-5px"
        }
    })
);

interface QuestionTextProps {
    question: QuestionTypeText;
    design: DesignGeneral;
    handleForward: HandleForwardType;
}

const QuestionText: FC<QuestionTextProps> = props => {
    const { question, design, handleForward } = props;
    const classes = useStyles();
    const state = useLeadGeneratorState();
    const dispatch = useLeadGeneratorDispatch();
    const [answer, setAnswer] = useState(state.questions[question._id] ? state.questions[question._id].answer : "");

    useEffect(() => {
        setAnswer(state.questions[question._id] ? state.questions[question._id].answer : "");
    }, [question._id]);

    const handleBlur = () =>
        dispatch({
            type: "updateQA",
            question: {
                questionId: question._id,
                question: question.title === "" ? question.description : question.title,
                answer: answer,
                restId: (question as any).restId
                //type: QuestionType.TEXT
            }
        });

    const handleTextInput = (event: any) => {
        if ((answer === "" && event.target.value !== "") || (event.target.value === "" && answer !== "")) {
            dispatch({
                type: "updateQA",
                question: {
                    questionId: question._id,
                    question: question.title === "" ? question.description : question.title,
                    answer: event.target.value
                }
            });
        }
        setAnswer(event.target.value);
    };

    return (
        <Grid className={classes.container} container justify={"space-around"} alignItems={"center"}>
            <Grid item xs={12}>
                <StyledTextField
                    value={answer}
                    variant={design.textField.variant}
                    error={false}
                    design={design.textField}
                    label={question.label}
                    helperText={question.helperText}
                    onChange={handleTextInput}
                    onBlur={handleBlur}
                />
            </Grid>
        </Grid>
    );
};

export default QuestionText;

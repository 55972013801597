import React, { FC, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, CircularProgress, createStyles, Grid, LinearProgress } from "@material-ui/core";
import { DesignGeneral } from "../../smashleads/interfaces/Design";

interface ISpinner {
    size?: string;
    icon?: string[];
    color?: string;
    className?: string;
}

const useProgressStyles = makeStyles(() =>
    createStyles({
        root: {
            flexGrow: 1,
            minHeight: 200
        },
        progressBox: {
            width: (props: DesignGeneral) => `${props.loading.length}%`
        },
        progressRoot: {
            height: (props: DesignGeneral) => `${props.loading.height}px`
        },
        progressColorPrimary: {
            backgroundColor: (props: DesignGeneral) => `${props.loading.backgroundColor}`,
            borderRadius: (props: DesignGeneral) => `${props.loading.barRadius}px`
        },
        progressBar: {
            borderRadius: (props: DesignGeneral) => `${props.loading.barRadius}px`,
            backgroundColor: (props: DesignGeneral) => `${props.loading.color}`
        },
        circularBar: {
            color: (props: DesignGeneral) => `${props.loading.color}`
        },
        defaultSpinner: {
            color: (props: DesignGeneral) => `${props.loading.color}`,
            size: (props: DesignGeneral) => `${props.loading.size}%`
        },
        textField: {
            color: (props: DesignGeneral) => props.fonts.content.color,
            fontFamily: (props: DesignGeneral) => props.fonts.content.fontFamily
        }
    })
);

const step = 50;
const total = 2000;

const ValuationProgress: FC<{ loadingType: string; design: DesignGeneral }> = ({ loadingType, design }) => {
    const classes = useProgressStyles(design);

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            // Just a simple loop to fake progress
            let newProgress = progress + 100 * (step / total);
            if (newProgress > 100) newProgress = 0;
            setProgress(newProgress);
        }, step);
    }, [progress]);

    if (loadingType === "circular")
        return (
            <Box position="relative" display="inline-flex">
                <CircularProgress
                    size={`${parseInt(design.loading.size) * 2}px`}
                    classes={{
                        root: classes.circularBar
                    }}
                    value={progress}
                />
            </Box>
        );

    if (loadingType === "linear")
        return (
            <Box display="flex" alignItems="center" className={classes.progressBox}>
                <Box width="100%" mr={1}>
                    <LinearProgress
                        classes={{
                            root: classes.progressRoot,
                            colorPrimary: classes.progressColorPrimary,
                            bar: classes.progressBar
                        }}
                        value={progress}
                    />
                </Box>
            </Box>
        );

    // SHOULD NEVER HAPPEN
    return <div />;
};

export default ValuationProgress;

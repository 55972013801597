import React, { FC } from "react";
import { ButtonBase, Card, createStyles, Grid, makeStyles, Theme, Tooltip, Typography } from "@material-ui/core";
import { HandleForwardType } from "../types";
import { QuestionIcon, QuestionTypeIcons } from "../../smashleads/interfaces/Question";
import { useLeadGeneratorDispatch } from "../generator-state";
import IconLoader from "../icon-loader";
import { DesignGeneral } from "../../smashleads/interfaces/Design";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const useStyles = makeStyles((muiTheme: Theme) =>
    createStyles({
        root: {
            paddingBottom: 24,
            display: "flex",
            justifyContent: "center",
            width: "100%"
        },
        iconButton: {
            boxShadow: (props: DesignGeneral) => props.icons.boxShadow,
            width: 180,
            borderRadius: (props: DesignGeneral) => props.icons.borderRadius + "px",
            [muiTheme.breakpoints.down("sm")]: { width: "100%" },
            [muiTheme.breakpoints.up("sm")]: { width: 180 },
            color: (props: DesignGeneral) => props.icons.color,
            "&:hover": {
                color: (props: DesignGeneral) => props.icons.color
            }
        },
        icon: {
            pointerEvents: "none",
            fontSize: (props: DesignGeneral) => props.icons.size + "px",
            color: (props: DesignGeneral) => props.icons.color,
            marginTop: "10px",
            [muiTheme.breakpoints.up("sm")]: {
                marginTop: "10px"
            },
            [muiTheme.breakpoints.down("sm")]: {
                marginTop: 0
            }
        },
        cardItem: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: 180,
            height: 120,
            border: (props: DesignGeneral) => `${props.icons.borderWidth}px solid ${props.icons.borderColor}`,
            borderRadius: (props: DesignGeneral) => props.icons.borderRadius + "px",
            backgroundColor: (props: DesignGeneral) => props.icons.backgroundColor,
            boxShadow: "none",
            [muiTheme.breakpoints.down("sm")]: {
                width: "100%",
                height: 80
            },
            [muiTheme.breakpoints.up("sm")]: {
                width: 180,
                height: 120
            },
            "&:hover": {
                border: (props: DesignGeneral) => `${props.icons.hoverBorderWidth}px solid ${props.icons.hoverColor}`
            }
        },
        descriptionField: {
            color: (props: DesignGeneral) => props.fonts.content.color,
            fontFamily: (props: DesignGeneral) => props.fonts.content.fontFamily,
            fontSize: (props: DesignGeneral) => props.fonts.content.size,
            fontWeight: (props: DesignGeneral) => +props.fonts.content.weight,
            [muiTheme.breakpoints.down(880)]: {
                fontSize: (props: DesignGeneral) => props.fonts.content.mobileSize
            },
            textAlign: "center",
            paddingTop: 10,
            paddingBottom: 5,
            [muiTheme.breakpoints.down("sm")]: {
                textAlign: "left"
            },
            [muiTheme.breakpoints.up("sm")]: {
                textAlign: "center"
            }
        },
        list: {
            minHeight: 240,
            flexGrow: 0
        }
    })
);

interface PageIconsProps {
    question: QuestionTypeIcons;
    design: DesignGeneral;
    handleForward: HandleForwardType;
    evaluationType?: "sprengnetter" | "priceHubble";
}

const QuestionIcons: FC<PageIconsProps> = props => {
    const { question, design, handleForward, evaluationType } = props;
    const classes = useStyles(design);
    const dispatch = useLeadGeneratorDispatch();
    const iconNum = question.allIconIds.length;

    const handleClick = (icon: QuestionIcon) => {
        dispatch({
            type: "updateQA",
            question: {
                questionId: question._id,
                restId: (question as any).restId,
                question: question.title === "" ? question.description : question.title,
                answer: icon.name
            }
        });

        handleForward(icon.target, icon._id);
    };

    return (
        <div className={classes.root}>
            <Grid
                container
                className={classes.list}
                spacing={2}
                justify={"center"}
                alignItems={"center"}
                style={{ maxWidth: iconNum === 5 ? 636 : 784, margin: "0px -8px 0px -8px" }}
            >
                {question.allIconIds.map((key: string, index: number) => {
                    const icon = question.icons[key];
                    return (
                        <Grid item key={key} xs={12} sm={iconNum % 2 === 0 ? 6 : 4} md={"auto"} lg={"auto"} xl={"auto"}>
                            <ButtonBase id={`${key} ${icon.name}`} className={`${key} ${classes.iconButton}`} onClick={() => handleClick(icon)}>
                                <Card
                                    id={`${key}`}
                                    className={`${key} ${classes.cardItem}`}
                                    style={{ opacity: evaluationType === "priceHubble" && icon.name === "Grundstück" ? 0.3 : 1 }}
                                >
                                    <Grid container alignItems={"center"} className={key} id={key}>
                                        <Grid item xs={4} sm={12} className={key} id={key}>
                                            {icon.name === "Egal" ? (
                                                <HelpOutlineIcon
                                                    style={{ color: design.icons.color, width: design.icons.size, height: design.icons.size }}
                                                    className={classes.icon}
                                                />
                                            ) : (
                                                <IconLoader
                                                    id={key}
                                                    className={`${key} ${classes.icon}`}
                                                    icon={icon.icon}
                                                    color={design.icons.color}
                                                    size={design.icons.size}
                                                />
                                            )}
                                        </Grid>
                                        <Grid container justifyContent="center" item xs={8} sm={12} className={key} id={key}>
                                            <Typography id={`${key}`} className={`${key} ${classes.descriptionField}`}>
                                                {question.icons[key].name}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </ButtonBase>
                        </Grid>
                    );
                })}
            </Grid>
        </div>
    );
};

export default QuestionIcons;

import React, { FC, useEffect, useState } from "react";

const defaultProps = {
    ["DE"]: {
        center: {
            lat: 51.16,
            lng: 10.45
        },
        zoom: 4
    },
    ["AT"]: {
        center: {
            lat: 47.41,
            lng: 13.2
        },
        zoom: 6
    },
    ["CH"]: {
        center: {
            lat: 46.800663464,
            lng: 8.222665776
        },
        zoom: 6
    },
    ["HU"]: {
        center: {
            lat: 47.1625,
            lng: 19.5033
        },
        zoom: 6
    }
};

interface GoogleMap {
    query: string | undefined;
    fields: string[];
    width?: number | string;
    height?: number | string;
    zoom?: number;
    envVar?: string;
}

const getCountryCode = (country?: string) => {
    switch (country) {
        case "Switzerland":
            return "CH";
        case "CH":
            return "CH";
        case "Austria":
            return "AT";
        case "AT":
            return "AT";
        default:
            return "DE";
    }
};

const GoogleMap: FC<GoogleMap> = ({ query, fields, width, height, zoom, envVar }) => {
    const mapsUrl = `https://www.google.com/maps/embed/v1/place?key=${envVar}`;

    const [frameSrc, setFrameSrc] = useState(`${mapsUrl}&zoom=${zoom}&q=${query}`);

    const country = "DE";
    useEffect(() => {
        if (query) {
            setFrameSrc(`${mapsUrl}&zoom=${zoom}&q=${query}`);
        } else {
            setFrameSrc(
                `${mapsUrl}&zoom=${defaultProps[country].zoom}&q=${country}&center=${defaultProps[country].center.lat},${defaultProps[country].center.lng}`
            );
        }
    }, [query]);

    return (
        <div
            id={"map"}
            style={{
                height: height ?? 152,
                width: width ?? 200
            }}
        >
            <iframe width={width ?? 200} height={height ?? 152} frameBorder="0" style={{ border: 0 }} src={frameSrc} allowFullScreen />
        </div>
    );
};

export default GoogleMap;

import * as React from "react";

const SvgReihenhaus = (props: React.SVGProps<SVGSVGElement>) => (
    <svg id="Reihenhaus_svg__GlobaleEbene" viewBox="0 0 1200 1200" width={props.width} height={props.height} {...props}>
        <g id="Reihenhaus_svg__PrimaryColorElements">
            <path
                id="Reihenhaus_svg__PrimaryColorElement"
                fill={props.color}
                d="M406.56 582.51l389.63-103.42-.23 698.43H406.39l.17-595.01-.17-.37"
            />
        </g>
        <g id="Reihenhaus_svg__BaseElements">
            <path
                fill="#e8e8e8"
                d="M656.83 1197.98H4.63V592.84l401.41-107.18.52 691.27 389.03 1.08.38-588.68 399.66-105.78v714.43H643.48"
            />
            <path
                fill="#fff"
                d="M463.5 930.4h106.48v269.22H463.5zM617.56 876.66h106.48V1197H617.56zM857.76 928.76h106.48v269.22H857.76zM1011.99 876.66h106.48V1197h-106.48zM77.78 928.76h106.48v269.22H77.78zM237.97 874.91h106.48v323.83H237.97z"
            />
            <path fill="#d1d1d1" d="M4.63 1177.52h1191v21.52H4.63z" />
        </g>
    </svg>
);

export default SvgReihenhaus;

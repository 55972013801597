import React, { FC, useEffect, useState } from "react";
import { createStyles, Grid, Hidden, makeStyles, Theme } from "@material-ui/core";
import { AddressAnswer, FieldType, HandleForwardType, QuestionType } from "../types";
import { useLeadGeneratorDispatch } from "../generator-state";
import PlacesAutocomplete from "../helpers/places-autocomplete";
import GoogleMap from "../helpers/google-map";
import { QuestionTypeAddress } from "../../smashleads/interfaces/Question";
import { DesignGeneral } from "../../smashleads/interfaces/Design";
import { useToggle } from "@onpreo/components";
import ManualAddress from "./maunal-address";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1
        },
        container: {
            minHeight: 200,
            maxHeight: 400,
            padding: theme.spacing(0),
            marginTop: "-5px"
        }
    })
);

interface PageAddressProps {
    question: QuestionTypeAddress;
    design: DesignGeneral;
    handleForward: HandleForwardType;
    envVar: string;
}

const QuestionAddress: FC<PageAddressProps> = props => {
    const { question, design, envVar } = props;
    const classes = useStyles();
    const dispatch = useLeadGeneratorDispatch();
    const [answer, setAnswer] = useState<AddressAnswer | null>(null);
    const [show, toggle] = useToggle();

    useEffect(() => {
        setAnswer(null);
    }, [question._id]);

    // const calcZoomLevel = useCallback((value: AddressAnswer | null) => {
    //     if (value && value.structured_formatting) {
    //         let numStrings;
    //         if (value.structured_formatting.secondary_text_matched_substrings)
    //             numStrings = value.structured_formatting.secondary_text_matched_substrings.length;
    //         else if (value.structured_formatting.secondary_text)
    //             numStrings = value.structured_formatting.secondary_text.split(",").length;
    //
    //         switch (numStrings) {
    //             case 1:
    //                 return 4;
    //             case 2:
    //                 return 11;
    //             case 3:
    //                 return 14;
    //             default:
    //                 return 4;
    //         }
    //     }
    //
    //     return 4;
    // }, []);

    const onChange = (value: AddressAnswer | null, type?: FieldType) => {
        if (value !== null) {
            dispatch({
                type: "updateQA",
                question: {
                    questionId: question._id,
                    question: question.title === "" ? question.description : question.title,
                    // answer: value,
                    type: QuestionType.ADDRESS,
                    restId: (question as any).restId,
                    // questionId: question._id,
                    // question: question.title === "" ? question.description : question.title,
                    answer: { ...value, fieldType: type, hasHouseNumber: value.hasHouseNumber }
                    // type: QuestionType.ADDRESS
                    /* xmlId: question.xmlId,
                     * restId: question.restId */
                }
            });
            setAnswer({ ...value, fieldType: type });
            if (value?.placeId === "noAddress") toggle();
        }
    };

    return (
        <div className={classes.root}>
            <Grid className={classes.container} container justify={"space-around"} alignItems={"center"}>
                {!show && (
                    <>
                        <Hidden mdUp>
                            <Grid container item sm={4} justify={"center"} style={{ margin: "16px 0 16px 0" }}>
                                <GoogleMap
                                    query={answer && answer.description !== "" ? answer.description : undefined}
                                    fields={["name", "geometry"]}
                                    zoom={answer ? 13 : 4}
                                    width={254}
                                    height={184}
                                    envVar={envVar}
                                />
                            </Grid>
                        </Hidden>
                        <Grid item xs={12} md={8}>
                            {/*<MultiPlacesAutocomplete design={design} value={answer} onChange={onChange} lang={lang} />*/}
                            {/*<AdvancedPlacesAutocomplete design={design} value={answer} onChange={onChange} lang={lang} />*/}
                            <PlacesAutocomplete question={question} design={design} value={answer} onChange={onChange} envVar={envVar} />
                        </Grid>
                        <Hidden smDown>
                            <Grid container item sm={4} justify={"center"}>
                                <GoogleMap
                                    query={answer && answer.description !== "" ? answer.description : undefined}
                                    fields={["name", "geometry"]}
                                    zoom={answer ? 14 : 4}
                                    envVar={envVar}
                                />
                            </Grid>
                        </Hidden>
                    </>
                )}
                {show && <ManualAddress design={design} question={question} />}
            </Grid>
        </div>
    );
};

export default QuestionAddress;

// https://kentcdodds.com/blog/how-to-use-react-context-effectively
import React, { createContext, useContext, useReducer } from "react";
import { QuestionType } from "./types";

export interface LeadQA {
    questionId: string;
    question: string;
    answer: string; // | CheckboxAnswer | AddressAnswer | CheckToInputAnswer | BubbleAnswer;
    type?: QuestionType;
    xmlId?: string;
    restId?: string;
    // Just used when type === QuestionType.VALUATION
    valuationId?: string;
}

export interface LeadContact {
    [key: string]: string | boolean;
}

export interface State {
    contact: Partial<LeadContact>;
    questions: { [questionId: string]: LeadQA };
    quiz: string;
    origin?: string;
    /* meta?: QuizMetadata; */
    leadId?: string;
    valuationError?: boolean;
}

const state: State = {
    contact: {},
    questions: {},
    quiz: "",
    origin: undefined,
    /* meta: undefined, */
    leadId: undefined,
    valuationError: false
};

const LeadGeneratorStateContext = createContext<State>(state);
const LeadGeneratorDispatchContext = createContext<any>(0);

function leadReducer(state: State, action: any) {
    switch (action.type) {
        case "updateQuiz":
            return { ...state, quiz: action.quiz };
        case "setOrigin":
            return { ...state, origin: action.origin };
        case "updateQA":
            const questions = { ...state.questions, [action.question.questionId]: action.question };
            return { ...state, questions: questions };
        case "updateContact":
            return { ...state, contact: action.contact };
        case "updateMeta":
            return { ...state, meta: action.meta };
        case "updateLeadId":
            return { ...state, leadId: action.leadId };
        case "updateValuationError":
            return { ...state, valuationError: action.valuationError };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}
function LeadGeneratorStateProvider({ children }: any) {
    const [lgState, dispatch] = useReducer(leadReducer, { contact: {}, questions: {}, quiz: "" });
    return (
        <LeadGeneratorStateContext.Provider value={lgState}>
            <LeadGeneratorDispatchContext.Provider value={dispatch}>{children}</LeadGeneratorDispatchContext.Provider>
        </LeadGeneratorStateContext.Provider>
    );
}

function useLeadGeneratorState() {
    const context = useContext(LeadGeneratorStateContext);
    if (context === undefined) {
        throw new Error("useLeadGeneratorState must be used within a LeadGeneratorProvider");
    }
    return context;
}

function useLeadGeneratorDispatch() {
    const context = useContext(LeadGeneratorDispatchContext);
    if (context === undefined) {
        throw new Error("useLeadGeneratorDispatch must be used within a LeadGeneratorProvider");
    }
    return context;
}

export { LeadGeneratorStateProvider, useLeadGeneratorState, useLeadGeneratorDispatch };

import { Slider, SliderProps, withStyles } from "@material-ui/core";
import { ChangeEvent } from "react";

interface QuestionSliderProps extends Omit<SliderProps, "onChange"> {
    onChange: (event: ChangeEvent<{}>, value: number | number[]) => void;
}

const QuestionSlider = withStyles(theme => ({
    root: {
        color: theme.palette.primary.main,
        height: 8
    },
    thumb: {
        height: 36,
        width: 36,
        backgroundColor: "#fff",
        border: "2px solid currentColor",
        marginTop: -16,
        marginLeft: -18,
        "&:focus,&:hover,&$active": {
            boxShadow: "inherit"
        },
        "& span": {
            display: "flex",
            marginLeft: 6,
            marginTop: 4,
            backgroundColor: "transparent",
            whiteSpace: "nowrap"
        }
    },
    active: {},
    track: {
        height: 8,
        borderRadius: 4
    },
    rail: {
        height: 8,
        borderRadius: 4
    },
    mark: {
        // @ts-ignore
        backgroundColor: theme.palette.white.main,
        height: 4,
        width: 4,
        borderRadius: 2,
        marginTop: 2
    },
    markActive: {
        opacity: 1,
        // @ts-ignore
        backgroundColor: theme.palette.gray[200]
    },
    markLabel: {}
}))((props: QuestionSliderProps) => <Slider {...props} />);

export default QuestionSlider;

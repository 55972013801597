import React from "react";
import { makeStyles, Paper, Theme } from "@material-ui/core";
import { PaperProps } from "@material-ui/core/Paper";

export const backgroundDefaultPadding = 30;
export const backgroundDefaultMargin = 20;

const useStyles = makeStyles(muiTheme => ({
    root: (props: any) => {
        return {
            display: "flex",
            textAlign: "center",
            borderWidth: props.borderWidth,
            borderStyle: "solid",
            borderRadius: props.borderRadius + "px",
            borderColor: props.borderColor,
            boxShadow: props.boxShadow,
            backgroundColor: props.backgroundColor,

            padding: backgroundDefaultPadding,
            marginTop: backgroundDefaultMargin,
            marginBottom: backgroundDefaultMargin,
            width: "100%",
            maxWidth: "100%",
            overflow: "visible",
            minHeight: 464,
            [muiTheme.breakpoints.down("xs")]: {
                // paddingTop: 0,
                margin: 0,
                marginTop: 0,
                marginBottom: 0,
                minHeight: "inherit"
            }
        };
    }
}));

interface Props extends PaperProps {
    design: any;
}

const PaperBackground = (props: Props) => {
    const { design, ...other } = props;
    const classes = useStyles(design.backgroundCard);
    return <Paper className={`${classes.root}`} {...other} />;
};

export default PaperBackground;

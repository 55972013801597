import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { ButtonProps } from "@material-ui/core/Button";
import { DesignButtons } from "./types";

const useStyles = makeStyles({
    root: {
        textTransform: "none",
        whiteSpace: "pre-wrap",
        textAlign: "center",
        paddingTop: 8,
        paddingBottom: 8,
        minHeight: 48,
        height: "100%",
        backgroundColor: (props: DesignButtons) => props.backgroundColor,
        borderWidth: (props: DesignButtons) => props.borderWidth,
        borderStyle: "solid",
        borderRadius: (props: DesignButtons) => props.borderRadius + "px",
        borderColor: (props: DesignButtons) => props.borderColor,
        boxShadow: (props: DesignButtons) => props.boxShadow,
        color: (props: DesignButtons) => props.color,
        paddingLeft: (props: DesignButtons) => props.padding,
        paddingRight: (props: DesignButtons) => props.padding,
        margin: (props: DesignButtons) => props.margin,
        "&:hover": {
            backgroundColor: (props: DesignButtons) => props.hoverColor,
            "@media (hover: none)": {
                backgroundColor: (props: DesignButtons) => props.backgroundColor
            }
        },
        "&:hover.Mui-disabled": {
            backgroundColor: (props: DesignButtons) => props.backgroundColor
        },
        "&:disabled": {
            opacity: "60%",
            backgroundColor: (props: DesignButtons) => props.backgroundColor,
            color: (props: DesignButtons) => props.color
        }
    },
    label: {
        pointerEvents: "none"
    }
});

interface CustomButtonProps extends ButtonProps {
    design: DesignButtons;
    gtmClass?: string;
    contentName?: string;
}

function QuestionButton(props: CustomButtonProps) {
    const { design, gtmClass, onClick, ...other } = props;
    const classes = useStyles(design);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        // if gtmClass exists we want to send that to facebook pixel
        if (onClick) onClick(event);
    };

    return (
        <Button
            {...other}
            id={`${gtmClass ? " " + gtmClass : ""}`}
            className={`${classes.root} ${gtmClass ? gtmClass : ""}`}
            classes={{
                label: `${gtmClass} ${classes.label}`
            }}
            onClick={handleClick}
        />
    );
}

export default QuestionButton;

import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import React, { FC, Fragment } from "react";
import { DesignGeneral } from "../smashleads/interfaces/Design";
import CustomButton from "./button";

const useStyles = makeStyles({
    container: {
        paddingTop: 16
    }
});

interface PageNextButtonProps {
    design: DesignGeneral;
    isMobile: boolean;
    value: string;
    contentName?: string;
    hideForward?: boolean;
    handleForward(): void;
    hideBackward?: boolean;
    handleBack?(): void;
    loading?: boolean;
    disabled?: boolean;
    type?: "button" | "submit" | "reset";
}

const PageNextButton: FC<PageNextButtonProps> = ({
    design,
    isMobile,
    value,
    hideBackward,
    hideForward,
    handleForward,
    handleBack,
    loading,
    contentName,
    disabled,
    type
}) => {
    const classes = useStyles();
    return (
        <Fragment>
            {!(hideBackward && hideForward) && (
                <Grid container className={classes.container} justify={isMobile ? "flex-start" : "center"}>
                    {!hideBackward && isMobile && (
                        <Grid container item justify={"flex-start"} alignItems={"center"} xs={2}>
                            <CustomButton
                                size="small"
                                design={design.backButton}
                                id={"backward-button"}
                                key={"backward-button"}
                                onClick={handleBack}
                                type={type}
                            >
                                <ChevronLeft />
                            </CustomButton>
                        </Grid>
                    )}

                    {!hideForward && (
                        <Grid
                            item
                            xs={isMobile ? (hideBackward ? 12 : 10) : undefined}
                            container
                            style={{ width: isMobile ? "100%" : "auto", marginBottom: isMobile ? 0 : 40 }}
                            justify={isMobile ? (hideBackward ? "center" : "flex-end") : "center"}
                        >
                            <CustomButton
                                size="small"
                                type={"submit"}
                                design={design.buttons}
                                onClick={handleForward}
                                disabled={disabled}
                                contentName={contentName}
                                style={{
                                    width: isMobile ? (hideBackward ? "100%" : "90%") : "auto",
                                    padding: isMobile ? "8px 0px 8px 0px" : undefined
                                }}
                            >
                                {loading ? <CircularProgress style={{ color: design.buttons.color }} size={24} /> : value}
                            </CustomButton>
                        </Grid>
                    )}
                </Grid>
            )}
        </Fragment>
    );
};

export default PageNextButton;

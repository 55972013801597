import * as React from "react";

const SvgMehrfamilienhaus = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="Mehrfamilienhaus_svg__GlobaleEbene"
        viewBox="0 0 1198.11 1198"
        width={props.width}
        height={props.height}
        {...props}
    >
        <g id="Mehrfamilienhaus_svg__PrimaryColorElements">
            <path fill="#dedede" d="M57.83 340.74l761.45 222.1 15.61 610.82H62.17l-4.34-832.92z" />
            <path fill="#e8e8e8" d="M1136.97 1197.88H90.5V481.81l709.42-143.55 337.05 122.27v737.35" />
            <path fill="#dedede" d="M2.11 1173.66h1196v24.21H2.11z" />
            <path
                fill="#fff"
                d="M133.57 986.31h103.71v138.91H133.57zM272.67 986.31h103.71v138.91H272.67zM133.57 739.23h103.71v138.91H133.57zM272.67 739.23h103.71v138.91H272.67zM133.57 525.17h103.71v138.91H133.57zM272.67 525.17h103.71v138.91H272.67zM498.57 525.17h103.71v138.91H498.57zM637.67 525.17h103.71v138.91H637.67zM498.57 755.99h103.71V894.9H498.57zM637.67 755.99h103.71V894.9H637.67zM853.77 525.17h103.71v138.91H853.77zM992.88 525.17h103.71v138.91H992.88zM853.77 768.07h103.71v138.91H853.77zM992.88 768.07h103.71v138.91H992.88zM853.77 986.31h103.71v138.91H853.77zM992.88 986.31h103.71v138.91H992.88z"
            />
            <path
                fill="#f5f5f5"
                d="M853.77 525.17l81.24 138.91h-81.24V525.17zM992.88 525.17l81.24 138.91h-81.24V525.17zM853.77 768.07l81.24 138.91h-81.24V768.07zM992.88 768.07l81.24 138.91h-81.24V768.07zM853.77 986.31l81.24 138.91h-81.24V986.31zM992.88 986.31l81.24 138.91h-81.24V986.31zM637.67 755.99l81.24 138.91h-81.24V755.99zM637.67 525.67l81.24 138.91h-81.24V525.67zM498.57 525.17l81.23 138.91h-81.23V525.17zM498.57 755.99L579.8 894.9h-81.23V755.99zM272.67 525.17l81.24 138.91h-81.24V525.17zM272.67 739.23l81.24 138.91h-81.24V739.23zM133.56 739.23l81.24 138.91h-81.24V739.23zM133.56 525.17l81.24 138.91h-81.24V525.17zM133.56 986.31l81.24 138.91h-81.24V986.31zM272.67 986.31l81.24 138.91h-81.24V986.31z"
            />
        </g>
        <path fill={props.color} d="M509.24 986.31h208.99v187.11H509.24z" id="Mehrfamilienhaus_svg__BaseElements" />
    </svg>
);

export default SvgMehrfamilienhaus;

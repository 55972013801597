import { Box, Grid, Typography, useMediaQuery } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { DesignGeneral } from "../../smashleads/interfaces/Design";
import { ContactType, QuestionTypeContact } from "../../smashleads/interfaces/Question";
import StyledContactTextField from "../helpers/styled-contact-textfield";
import { ControlledSwitch } from "@onpreo/components";
import { contactTypeToField, getCustomFieldName, localePageContact } from "../questions";
import { useLeadGeneratorState, useLeadGeneratorDispatch, LeadContact } from "../generator-state";
import PageNextButton from "../page-next-button";

interface QuestionContactProps {
    question: QuestionTypeContact;
    design: DesignGeneral;
    onCheckContactExists?: (email: string) => Promise<{ email: string; name: string; phone: string }>;
    onAfterSave?: () => void;
    visibleSaveContactBtn: boolean;
}

interface FormFields extends LeadContact {
    form: string;
}

const QuestionContact: FC<QuestionContactProps> = props => {
    const { question, design, onCheckContactExists, onAfterSave, visibleSaveContactBtn } = props;
    const {
        control,
        formState: { errors },
        watch,
        setValue,
        handleSubmit,
        setError
    } = useFormContext();
    const isMobile = useMediaQuery("(max-width:600px)");
    const locale = localePageContact["de"];
    const [show, setShow] = useState(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(true);
    const state = useLeadGeneratorState();
    const dispatch = useLeadGeneratorDispatch();

    useEffect(() => {
        const { unsubscribe } = watch(values => {
            delete values.form;
            const checkedValues = Object.keys(values).filter(val => !!values[val]);
            let reqFieldsProvided = true;
            for (const id of question.allContactFieldsIds) {
                const { helperText, required, type } = question.contactFields[id];

                if (required) {
                    reqFieldsProvided =
                        type === ContactType.CUSTOM
                            ? checkedValues.includes(getCustomFieldName(helperText))
                            : checkedValues.includes(contactTypeToField(type));
                    if (!reqFieldsProvided) break;
                }
            }
            if (watch("noNeedOwner")) {
                setDisabled(false);
            } else {
                setDisabled(checkedValues.length === 0 || !reqFieldsProvided || Object.keys(errors).length !== 0 || loading);
            }
        });
        return () => unsubscribe();
    }, [watch, errors]);

    useEffect(() => {
        if (show)
            (async () => {
                const [firstName, lastName] = show?.name?.split(" ");
                setValue("firstName", firstName, { shouldValidate: true });
                setValue("lastName", lastName, { shouldValidate: true });
                setValue("email", show?.email, { shouldValidate: true });
                setValue("phone", show?.phone, { shouldValidate: true });
            })();
    }, [show]);

    const checkExists = async (value: string) => {
        const existingDoc = await onCheckContactExists(value);
        if (existingDoc?.email === value) setShow(existingDoc);
    };

    const handleSaveContact = async (data: FormFields | any) => {
        if (data.form) return setError("form", { type: "validation" });
        if (Object.keys(errors).length === 0) {
            data.origin = state.origin;
            dispatch({ type: "updateContact", contact: data });
        }

        if (onAfterSave) onAfterSave();
    };

    return (
        <form onSubmit={handleSubmit(handleSaveContact)} style={{ width: "100%" }}>
            <Grid item xs={12} style={{ marginTop: 16 }}>
                <Typography align="center" variant="h3">
                    Eigentümerinformationen
                </Typography>
            </Grid>
            <Grid item xs={12} container justifyContent={"center"} style={{ margin: 16 }}>
                <ControlledSwitch control={control} name={"noNeedOwner"} />
                <Typography style={{ paddingLeft: 10, fontSize: 15 }}>Ich habe keine Eigentümerinformationen</Typography>
            </Grid>

            {!watch("noNeedOwner") && (
                <Box mb={1}>
                    <Grid justifyContent={"space-between"} alignItems={"center"} container spacing={1} style={{ padding: "0 4px" }}>
                        {question.allContactFieldsIds.map(id => {
                            const { helperText, required, type } = question.contactFields[id];
                            const fieldName = type === ContactType.CUSTOM ? getCustomFieldName(helperText) : contactTypeToField(type);
                            let errorMessage = undefined;
                            if (errors[fieldName]) errorMessage = errors[fieldName].message;
                            let inputType = undefined;

                            const len = question.allContactFieldsIds.length;
                            let pos: any = 12;
                            if (len > 4 && len <= 8) pos = 6;
                            if (len > 8) pos = 4;

                            let pattern = undefined;
                            if (type === ContactType.PHONE) {
                                inputType = "tel";
                                pattern = {
                                    value: /^undefined$|^[0-9+() -]*$/,
                                    message: `${locale.invalid} ${helperText}`
                                };
                            } else if (type === ContactType.EMAIL) {
                                inputType = "email";
                                pattern = {
                                    value: /^undefined$|^(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i,
                                    message: `${locale.invalid} ${helperText}`
                                };
                            } else if (type === ContactType.POSTAL_CODE)
                                pattern = {
                                    value: /^undefined$|^[0-9 ]*$/,
                                    message: `${locale.invalid} ${helperText}`
                                };

                            return (
                                <Grid item key={type} xs={12} sm={6}>
                                    <Controller
                                        key={type}
                                        name={fieldName}
                                        render={({ field }) => (
                                            <StyledContactTextField
                                                error={!!errors[fieldName]}
                                                variant={design.textField.variant as any}
                                                name={helperText}
                                                label={`${helperText} ${!required ? "(optional)" : ""}`}
                                                id={helperText}
                                                design={design.textField}
                                                helperText={errorMessage}
                                                type={inputType}
                                                checkExists={checkExists}
                                                full={true}
                                                {...field}
                                            />
                                        )}
                                        control={control}
                                        rules={{
                                            required: { value: required, message: locale.required },
                                            minLength: { value: 1, message: locale.input_too_short },
                                            maxLength: { value: 64, message: locale.input_too_long },
                                            pattern: pattern
                                        }}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                    {show && (
                        <Grid container justifyContent={"center"}>
                            <Grid item xs={12}>
                                <Typography align="center" style={{ fontSize: 14 }}>
                                    Diese E-Mail-Adresse liegt bereits in Ihrem System vor. Die hier erstellte Immobilie wird dem im System hinterlegten
                                    Kontakt zugewiesen. Falls Sie für diese Immobilie einen neuen Eigentümer erstellen möchten, geben Sie bitte eine neue,
                                    eindeutige E-Mail-Adresse an.
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </Box>
            )}

            {visibleSaveContactBtn && (
                <Grid item container justifyContent={"center"}>
                    <PageNextButton
                        value={watch("noNeedOwner") ? "Eingabe bestätigen" : "Besitzer hinzufügen"}
                        design={design}
                        isMobile={isMobile}
                        handleForward={() => {}}
                        contentName={question.title}
                        loading={loading}
                        disabled={disabled}
                        type={"submit"}
                    />
                </Grid>
            )}
        </form>
    );
};

export default QuestionContact;

import * as React from "react";

const SvgPraxis = (props: React.SVGProps<SVGSVGElement>) => (
    <svg id="Praxis_svg__GlobaleEbene" viewBox="0 0 1200 1200" width={props.width} height={props.height} {...props}>
        <g id="Praxis_svg__BaseElements">
            <path fill="#fff" d="M477.77 576.44h147.68V756.2H477.77z" />
            <path fill="#f5f5f5" d="M301.14 576.45l116.35 179.76H301.14V576.45z" />
            <path fill="#f2f2f2" d="M477.77 576.45l116.35 179.76H477.77V576.45z" />
            <path fill="#f5f5f5" d="M122.48 576.45l116.36 179.76H122.48V576.45z" />
            <path fill="#dedede" d="M47.21 286.67l592.16 153.46v759.78H51.45l-4.24-913.24z" />
            <path fill="#e8e8e8" d="M667 1199.91H79.08V501.47l590.45-123.71v115.62L667 1199.91" />
            <path fill="#fff" d="M186.67 948.05h156.62v251.86H186.67z" />
            <path fill="#dedede" d="M0 1176.29h1200v23.62H0z" />
            <path
                fill="#fff"
                d="M122.48 576.44h147.68V756.2H122.48zM301.14 576.44h147.68V756.2H301.14zM400.17 960.86h82.62v91.22h-82.62zM518.13 960.86h82.62v91.22h-82.62z"
            />
            <path fill="#e8e8e8" d="M668.29 839h474.4v337.29h-474.4z" />
            <path fill="#dedede" d="M668.29 839l283.82 337.29H668.29V839z" />
        </g>
        <g id="Praxis_svg__PrimaryColorElements" fill={props.color}>
            <path d="M712.81 924.17h387.49v252.12H712.81zM730.13 567.84h136.84v180.91H730.13zM668.66 674.53h60.68v19.71h-60.68zM668.66 596.83h61.46v19.71h-61.46z" />
        </g>
        <path opacity={0.06} d="M712.73 923.86v252.81l239.38-.38-212.15-252.12" />
        <text
            transform="translate(747.32 715.71)"
            fontSize={153.077}
            fill="#fff"
            fontFamily="Futura-Bold,Futura"
            fontWeight={700}
        >
            {"+"}
        </text>
    </svg>
);

export default SvgPraxis;

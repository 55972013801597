import { makeStyles } from "@material-ui/styles";
import { Card, createStyles, Grid, Theme, Typography } from "@material-ui/core";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { HandleForwardType } from "../types";
import { QuestionTypeSlider } from "../../smashleads/interfaces/Question";
import { useLeadGeneratorDispatch, useLeadGeneratorState } from "../generator-state";
import StyledSlider from "../helpers/question-slider";
import StyledTextField from "../helpers/styled-textfield";
import { DesignGeneral } from "../../smashleads/interfaces/Design";

const useStyles = makeStyles((muiTheme: Theme) =>
    createStyles({
        container: {
            minHeight: 200,
            maxHeight: 400,
            padding: muiTheme.spacing(0)
        },
        sliderContainer: (props: DesignGeneral) => ({
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: props.slider.withCard ? 24 : 0
        }),
        // Used to bind together the slider and the min/max labels
        sliderWrapper: {
            // Will be center aligned by sliderContainer
            width: "80%",
            [muiTheme.breakpoints.down("sm")]: {
                width: "85%"
            }
        },
        slider: {
            width: "100%",
            marginTop: 36,
            [muiTheme.breakpoints.down("sm")]: {
                marginLeft: muiTheme.spacing(0),
                marginRight: muiTheme.spacing(0),
                marginTop: 0
            }
        },
        thumb: {
            border: (props: DesignGeneral) => "2px solid " + props.slider.thumbColor,
            "& span": {
                color: (props: DesignGeneral) => props.slider.thumbFontColor,
                fontSize: 18,
                fontFamily: (props: DesignGeneral) => props.fonts.content.fontFamily
            }
        },
        track: {
            color: (props: DesignGeneral) => props.slider.trackColor
        },
        rail: {
            color: (props: DesignGeneral) => props.slider.railColor
        },
        mark: {
            backgroundColor: "transparent"
        },
        markLabel: {
            color: (props: DesignGeneral) => props.slider.labelColor,
            fontFamily: (props: DesignGeneral) => props.fonts.content.fontFamily,
            fontSize: (props: DesignGeneral) => props.fonts.content.size,
            [muiTheme.breakpoints.down(880)]: {
                fontSize: (props: DesignGeneral) => props.fonts.content.mobileSize
            },
            fontWeight: (props: DesignGeneral) => +props.fonts.content.weight,
            [muiTheme.breakpoints.down("sm")]: {
                fontSize: (props: DesignGeneral) => 16
            }
        },
        sliderTextField: {
            width: "40%",
            minWidth: 144,
            [muiTheme.breakpoints.down("sm")]: {
                marginLeft: muiTheme.spacing(0),
                marginRight: muiTheme.spacing(0),
                width: "85%"
            }
        },
        sliderCard: {
            paddingTop: 56,
            paddingBottom: 32,
            width: "100%",
            marginBottom: 24,
            marginTop: 24,
            borderWidth: (props: DesignGeneral) => props.slider.borderWidth,
            borderStyle: "solid",
            borderRadius: (props: DesignGeneral) => props.slider.borderRadius + "px",
            borderColor: (props: DesignGeneral) => props.slider.borderColor,
            boxShadow: (props: DesignGeneral) => props.slider.boxShadow,
            backgroundColor: (props: DesignGeneral) => props.slider.backgroundColor
        },
        transparentCard: {
            margin: muiTheme.spacing(3, -3),
            width: "calc(100% + 48px)",
            backgroundColor: "transparent",
            boxShadow: "none",
            borderRadius: 0,
            paddingTop: 56,
            paddingBottom: 32
        }
    })
);

export const roundSliderValue = (value: number, scala: number, min: number) => {
    return Math.round((value - min) / scala) * scala + min;
};

interface QuestionSliderProps {
    question: QuestionTypeSlider;
    design: DesignGeneral;
    handleForward: HandleForwardType;
    lang: "en" | "de";
}

const localePageSlider = {
    en: {
        no_input: "No input"
    },
    de: {
        no_input: "Keine Angabe"
    }
};

const QuestionSlider: FC<QuestionSliderProps> = props => {
    const { question, design, lang = "en" } = props;
    const classes = useStyles(design);
    const { min, max, scala, unit, continuous } = question;
    const state = useLeadGeneratorState();
    const dispatch = useLeadGeneratorDispatch();

    const locale = localePageSlider["de"];

    const [value, setValue] = useState<number>(0);
    const [textValue, setTextValue] = useState("");

    const averageValue = continuous ? Math.round((min + max) / 2) : roundSliderValue(Math.round((min + max) / 2), scala, min);

    useEffect(() => {
        setValue(
            state.questions[question._id] &&
                state.questions[question._id].answer &&
                state.questions[question._id].answer !== "" &&
                state.questions[question._id].answer !== locale.no_input
                ? parseInt(state.questions[question._id].answer as string)
                : averageValue
        );
        setTextValue(
            state.questions[question._id] && state.questions[question._id].answer && state.questions[question._id].answer !== ""
                ? (state.questions[question._id].answer as string)
                : ""
        );
    }, [question]);

    function valueText(value: number) {
        return `${value}${unit}`;
    }

    const onTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        const parsed = parseInt(newValue);
        if ((textValue === "" && newValue !== "" && !isNaN(parsed)) || (textValue !== "" && newValue === "")) {
            dispatch({
                type: "updateQA",
                question: {
                    questionId: question._id,
                    restId: (question as any).restId,
                    question: question.title === "" ? question.description : question.title,
                    answer: newValue === "" ? locale.no_input : newValue
                }
            });
        }

        if (newValue === "") {
            setTextValue(newValue);
            setValue(averageValue);
        }
        if (!isNaN(parsed)) {
            setTextValue(newValue);
            if (parsed < min) setValue(min);
            else if (parsed > max) setValue(max);
            else setValue(continuous ? parsed : roundSliderValue(parsed, scala, min));
        }
    };

    const onSliderChange = (event: any, value: number | number[]) => {
        if (typeof value === "number") {
            // -> Should always be true since we are not using the multi slider
            setValue(value as number);
            setTextValue(value.toString());
        }
    };

    function handleCommit() {
        dispatch({
            type: "updateQA",
            question: {
                questionId: question._id,
                question: question.title === "" ? question.description : question.title,
                restId: (question as any).restId,
                answer: textValue === "" ? locale.no_input : textValue
            }
        });
    }

    return (
        <Grid className={classes.container} container justify={"space-around"} alignItems={"center"}>
            <Card className={design.slider.withCard ? classes.sliderCard : classes.transparentCard}>
                <Grid item className={classes.sliderContainer}>
                    <div className={classes.sliderWrapper}>
                        <StyledSlider
                            classes={{
                                root: classes.slider,
                                thumb: classes.thumb,
                                track: classes.track,
                                rail: classes.rail,
                                markLabel: classes.markLabel,
                                mark: classes.mark
                            }}
                            min={min as number}
                            max={max as number}
                            step={continuous ? undefined : scala}
                            marks={false}
                            onChange={onSliderChange as any}
                            onChangeCommitted={handleCommit}
                            value={value}
                            valueLabelFormat={valueText}
                            valueLabelDisplay="on"
                        />
                        <Grid container direction={"row"} justify={"space-between"} alignItems={"center"} wrap={"nowrap"} style={{ marginTop: -2 }}>
                            <Typography className={classes.markLabel} style={{ textAlign: "left" }}>{`${min} ${unit}`}</Typography>
                            <Typography className={classes.markLabel} style={{ textAlign: "right" }}>{`${max} ${unit}`}</Typography>
                        </Grid>
                    </div>
                </Grid>
                <Grid item>
                    <StyledTextField
                        className={classes.sliderTextField}
                        error={false}
                        design={design.textField}
                        variant={"outlined"}
                        value={textValue}
                        placeholder={locale.no_input}
                        onBlur={handleCommit}
                        InputProps={{
                            endAdornment: <span>{unit}</span>
                        }}
                        onChange={onTextFieldChange}
                    />
                </Grid>
            </Card>
        </Grid>
    );
};

export default QuestionSlider;

import { createStyles, makeStyles, StyleRules } from "@material-ui/core";

// Here we will turn all our Css into JavaScript Objects
const opacityStart = {
    opacity: 0
};

const opacityFinish = {
    opacity: 1
};

const transition = (duration: number) => `opacity ${duration}ms, transform ${duration}ms`;
const transitionFade = (duration: number) => `opacity ${duration}ms`;

type TransitionStyleRules = StyleRules<
    | "slideEnter"
    | "slideEnterActive"
    | "slideExit"
    | "slideExitActive"
    | "fadeEnter"
    | "fadeEnterActive"
    | "fadeExit"
    | "fadeExitActive"
    | "zoomEnter"
    | "zoomEnterActive"
    | "zoomExit"
    | "zoomExitActive"
>;

export const useForwardTransitionStyles = makeStyles(() =>
    createStyles({
        // Slide Forward Transition
        slideEnter: {
            "& .transition-container": {
                ...opacityStart,
                transform: "translateX(100%)"
            }
        },
        slideEnterActive: {
            "& .transition-container": {
                ...opacityFinish,
                transform: "translateX(0%)",
                transition
            }
        },
        slideExit: {
            "& .transition-container": {
                ...opacityFinish,
                transform: "translateX(0%)"
            }
        },
        slideExitActive: {
            "& .transition-container": {
                ...opacityStart,
                transform: "translateX(-100%)",
                transition
            }
        },

        // Fade Transition
        fadeEnter: {
            "& .transition-container": {
                ...opacityStart
            }
        },
        fadeEnterActive: {
            "& .transition-container": {
                ...opacityFinish,
                transition: transitionFade
            }
        },
        fadeExit: {
            "& .transition-container": {
                ...opacityFinish
            }
        },
        fadeExitActive: {
            "& .transition-container": {
                ...opacityStart,
                transition: transitionFade
            }
        },

        // Zoom Transition
        zoomEnter: {
            "& .transition-container": {
                ...opacityStart,
                transform: "scale(0)"
            }
        },
        zoomEnterActive: {
            "& .transition-container": {
                ...opacityFinish,
                transform: "scale(1)",
                transition
            }
        },
        zoomExit: {
            "& .transition-container": {
                ...opacityFinish,
                transform: "scale(1)"
            }
        },
        zoomExitActive: {
            "& .transition-container": {
                ...opacityStart,
                transform: "scale(0)",
                transition
            }
        }
    } as TransitionStyleRules)
);

export const useBackwardTransitionStyles = makeStyles(() =>
    createStyles({
        // Slide Forward Transition
        slideEnter: {
            "& .transition-container": {
                ...opacityStart,
                transform: "translateX(-100%)"
            }
        },
        slideEnterActive: {
            "& .transition-container": {
                ...opacityFinish,
                transform: "translateX(0%)",
                transition
            }
        },
        slideExit: {
            "& .transition-container": {
                ...opacityFinish,
                transform: "translateX(0%)"
            }
        },
        slideExitActive: {
            "& .transition-container": {
                ...opacityStart,
                transform: "translateX(100%)",
                transition
            }
        },

        // Fade Transition
        fadeEnter: {
            "& .transition-container": {
                ...opacityStart
            }
        },
        fadeEnterActive: {
            "& .transition-container": {
                ...opacityFinish,
                transition: transitionFade
            }
        },
        fadeExit: {
            "& .transition-container": {
                ...opacityFinish
            }
        },
        fadeExitActive: {
            "& .transition-container": {
                ...opacityStart,
                transition: transitionFade
            }
        },

        // Zoom Transition
        zoomEnter: {
            "& .transition-container": {
                ...opacityStart,
                transform: "scale(0)"
            }
        },
        zoomEnterActive: {
            "& .transition-container": {
                ...opacityFinish,
                transform: "scale(1)",
                transition
            }
        },
        zoomExit: {
            "& .transition-container": {
                ...opacityFinish,
                transform: "scale(1)"
            }
        },
        zoomExitActive: {
            "& .transition-container": {
                ...opacityStart,
                transform: "scale(0)",
                transition
            }
        }
    } as TransitionStyleRules)
);

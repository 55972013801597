import * as React from "react";

const SvgHaus = (props: React.SVGProps<SVGSVGElement>) => (
    <svg id="Haus_svg__GlobaleEbene" viewBox="0 0 1200 1200.12" width={props.width} height={props.height} {...props}>
        <g id="Haus_svg__BaseElements">
            <path fill="#dedede" d="M287.88 286.88l592.15 153.47v759.77H292.12l-4.24-913.24z" />
            <path fill="#e8e8e8" d="M907.66 1200.12H319.75V501.69L910.2 377.97v115.62l-2.54 706.53" />
            <path fill="#dedede" d="M233.54 1176.5h730.11v23.62H233.54z" />
            <path
                fill="#fff"
                d="M450.91 576.66h147.68v179.76H450.91zM640.83 576.66h147.68v179.76H640.83zM640.83 961.07h82.62v118.16h-82.62zM758.79 961.07h82.62v118.16h-82.62z"
            />
            <path
                fill="#f2f2f2"
                d="M640.83 576.66l116.35 179.76H640.83V576.66zM450.91 576.66l116.35 179.76H450.91V576.66z"
            />
        </g>
        <path fill={props.color} d="M427.34 948.26h156.62v228.38H427.34z" id="Haus_svg__PrimaryColorElements" />
    </svg>
);

export default SvgHaus;

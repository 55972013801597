import { makeStyles, createStyles, Theme, Grid, Hidden, Typography } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { FC, useEffect, useMemo, useState } from "react";
import { useLeadGeneratorState } from "./generator-state";
import PageNextButton from "./page-next-button";
import CustomButton from "./button";
import PaperBackground from "./paper-background";
import AnimationLoader, { Transition } from "./transitions/animation-loader";
import { AddressAnswer, HandleForwardType, Question, QuestionType } from "./types";
import { DesignGeneral } from "../smashleads";

const useStyles = makeStyles((muiTheme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            overflow: "visible",
            display: "flex",
            width: "50%",
            marginLeft: "auto",
            marginRight: "auto",
            // the + 256 comes from the margin that is applied around the original frame
            [muiTheme.breakpoints.down(1690 + 256)]: {
                width: "60%"
            },
            [muiTheme.breakpoints.down(1410 + 256)]: {
                width: "70%"
            },
            [muiTheme.breakpoints.down(1210 + 256)]: {
                width: "80%"
            },
            [muiTheme.breakpoints.down(1060 + 256)]: {
                width: "90%"
            },
            [muiTheme.breakpoints.down(950 + 256)]: {
                width: "100%"
            }
        },
        container: {
            alignContent: "space-between",
            minHeight: 400
        },
        titleField: {
            marginLeft: muiTheme.spacing(2),
            marginRight: muiTheme.spacing(2),
            width: "100%",
            textAlign: "center",
            color: (props: DesignGeneral) => props.fonts.header.color,
            fontFamily: (props: DesignGeneral) => props.fonts.header.fontFamily,
            fontSize: (props: DesignGeneral) => props.fonts.header.size,
            [muiTheme.breakpoints.down(880)]: {
                fontSize: (props: DesignGeneral) => props.fonts.header.mobileSize
            },
            fontWeight: (props: DesignGeneral) => +props.fonts.header.weight
            // hyphens: "auto"
        },
        descriptionField: {
            marginLeft: muiTheme.spacing(2),
            marginRight: muiTheme.spacing(2),
            width: "100%",
            textAlign: "center",
            color: (props: DesignGeneral) => props.fonts.description.color,
            fontFamily: (props: DesignGeneral) => props.fonts.description.fontFamily,
            fontSize: (props: DesignGeneral) => props.fonts.description.size,
            [muiTheme.breakpoints.down(880)]: {
                fontSize: (props: DesignGeneral) => props.fonts.description.mobileSize
            },
            fontWeight: (props: DesignGeneral) => +props.fonts.description.weight
            // hyphens: "auto"
        },
        headerRow: {
            marginBottom: 5
        }
    })
);

interface PageBaseProps {
    question: Question;
    pageNumber: number;
    handleForward: HandleForwardType;
    handleBackward(): void;
    isMobile: boolean;
    design: any;
    /* tracking: TrackingInfo; */
    /* Is the height that will be removed on mobile devices from the form, if landingPage is activated */
    /* landingPageHeight: number; */
}

export const PageBase: FC<PageBaseProps> = ({ children, question, pageNumber, handleForward, handleBackward, isMobile, design }) => {
    const classes = useStyles(design);
    const state = useLeadGeneratorState();
    /* let mobileHeight: number = 0;
     * if (typeof window !== "undefined") mobileHeight = useMemo(() => (window as any).innerHeight - 40 - landingPageHeight, []); */

    const width = useMemo(() => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth, []);

    const hideForward =
        question.type === QuestionType.ICONS ||
        question.type === QuestionType.IMAGES ||
        question.type === QuestionType.BIG_IMAGES ||
        question.type === QuestionType.CONTACT ||
        question.type === QuestionType.VALUATION ||
        question.type === QuestionType.THANK_YOU ||
        question.type === QuestionType.LOADING ||
        question.type === QuestionType.FIRST_SELECTION ||
        question.type === QuestionType.DROPDOWN;

    const hideBackward =
        pageNumber === 1 ||
        (question.type === QuestionType.CONTACT && (isMobile || !design.contact.displayBackButton)) ||
        question.type === QuestionType.THANK_YOU ||
        question.type === QuestionType.LOADING ||
        (question.type === QuestionType.VALUATION && !state?.valuationError);

    const hideMobileBackward = hideBackward || question.type === QuestionType.DROPDOWN;

    const [pageNumberHistory, setPageNumberHistory] = useState<[number, number] | undefined>(undefined);
    useEffect(() => {
        setPageNumberHistory(prev => {
            if (prev === undefined) return [0, pageNumber];
            else return [prev[1], pageNumber];
        });
        if (typeof document !== "undefined") document.documentElement.scrollTop = 0;
    }, [pageNumber]);

    const forward = !(pageNumberHistory !== undefined && pageNumberHistory[0] > pageNumberHistory[1]);

    const disabled = useMemo(() => {
        /* const checkCheckboxAnswer = (data: CheckboxAnswer) => {
         *     return Object.values(data.data).some(r => r);
         * }; */
        if (question.type === QuestionType.ADDRESS) {
            if (state.questions[question._id] === undefined) return true;
            const answer = state.questions[question._id].answer as unknown as AddressAnswer;
            return !answer.hasHouseNumber;
        }

        //@ts-ignore
        if (question.type === QuestionType.PIPELINE) {
            if (state.questions[question._id] === undefined) return true;
            const { tag, tab, gsoAktion, days, voucher, deadline, partnerId, customPipeline, isCustom } = state.questions[question._id]
                .answer as unknown as {
                tag?: string;
                tab: number;
                gsoAktion?: string;
                days?: string;
                voucher?: string;
                deadline?: string;
                partnerId?: string;
                customPipeline?: string;
                isCustom?: boolean;
            };

            const disabledGsoTag = tag === "GSO" && (gsoAktion === undefined || days === undefined || voucher === undefined || deadline === undefined);
            const disabledIfCustom = isCustom && (customPipeline === undefined || customPipeline === "default");
            const disablesIfNotCustom = !isCustom && disabledGsoTag;
            const disabledFirstTab = tab === 0 && (disablesIfNotCustom || disabledIfCustom);

            return disabledFirstTab;
        }

        /* const checkBubbleAnswer = (data: BubbleAnswer) => {
         *     return Object.values(data.data).some(r => r);
         * }; */

        return (
            (question.type === QuestionType.CHECKBOX ||
                question.type === QuestionType.BUBBLE ||
                question.type === QuestionType.SLIDER ||
                question.type === QuestionType.TEXT) &&
            (!state.questions[question._id] ||
                !state.questions[question._id].answer ||
                state.questions[question._id].answer === "" ||
                (question.type === QuestionType.SLIDER && state.questions[question._id].answer === "Keine Angabe"))
        );
    }, [state.questions[question._id], question._id]);

    /* const isTitleMaxWidth =
     *     !isMobile && (design.proofLogos.leftBanner || design.proofLogos.rightBanner) && (question.type === QuestionType.CONTACT || pageNumber === 1); */

    return (
        <div className={classes.root} style={{ maxWidth: "100%" }}>
            <AnimationLoader
                childKey={question._id}
                isForward={forward}
                forward={design.transitions.forward as Transition}
                backward={design.transitions.backward as Transition}
                disable={design.transitions.animationType !== "slide"}
                isMobile={isMobile}
            >
                <PaperBackground design={design}>
                    <AnimationLoader
                        isForward={forward}
                        forward={design.transitions.forward as Transition}
                        backward={design.transitions.backward as Transition}
                        childKey={question._id}
                        disable={design.transitions.animationType !== "content"}
                        isMobile={isMobile}
                    >
                        <Grid container justify={"center"} className={classes.container}>
                            <Grid container justify={"flex-start"} className={classes.headerRow}>
                                <Hidden xsDown>
                                    {!hideBackward && (
                                        <CustomButton
                                            size="small"
                                            onClick={handleBackward}
                                            disabled={hideBackward}
                                            design={design.backButton}
                                            id={"backward-button"}
                                            key={"backward-button"}
                                        >
                                            <ChevronLeft fontSize="large" style={{ width: 24, height: 24 }} />
                                            {/* <i className={"fas fa-chevron-left fa-lg"} /> */}
                                        </CustomButton>
                                    )}
                                </Hidden>
                            </Grid>
                            <Grid container justify={"center"}>
                                {/* To correctly handle carriage return */}
                                {/* https://stackoverflow.com/questions/39325414/line-break-in-html-with-n */}
                                <Typography
                                    id="headline"
                                    style={{
                                        textAlign: "center",
                                        whiteSpace: "pre-line",
                                        maxWidth: "80%",
                                        display: "flex",
                                        justifyContent: "center"
                                    }}
                                    variant="h5"
                                    component="h1"
                                    gutterBottom
                                    className={classes.titleField}
                                >
                                    {question.title}
                                </Typography>
                            </Grid>
                            {question.description && (
                                <Grid container justify={"space-around"}>
                                    <Typography
                                        id="description"
                                        style={{
                                            textAlign: "center",
                                            whiteSpace: "pre-line",
                                            display: "flex",
                                            justifyContent: "center"
                                        }}
                                        variant="body1"
                                        component="p"
                                        gutterBottom
                                        className={classes.descriptionField}
                                    >
                                        {question.description}
                                    </Typography>
                                </Grid>
                            )}
                            <Grid container justify={"center"}>
                                <AnimationLoader
                                    isForward={forward}
                                    forward={design.transitions.forward as Transition}
                                    backward={design.transitions.backward as Transition}
                                    childKey={question._id}
                                    disable={design.transitions.animationType !== "content-type"}
                                    isMobile={isMobile}
                                >
                                    {children}
                                </AnimationLoader>
                            </Grid>
                            {(!hideMobileBackward || !hideForward) && (
                                <Grid container>
                                    <PageNextButton
                                        value={question.button}
                                        design={design}
                                        hideBackward={hideMobileBackward}
                                        hideForward={hideForward}
                                        isMobile={!!isMobile}
                                        handleForward={() => handleForward(question.target)}
                                        handleBack={handleBackward}
                                        contentName={question.title}
                                        disabled={disabled}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </AnimationLoader>
                </PaperBackground>
            </AnimationLoader>
        </div>
    );
};

export default PageBase;

import * as React from "react";

const SvgSonstiges = (props: React.SVGProps<SVGSVGElement>) => (
    <svg id="Sonstiges_svg__GlobaleEbene" viewBox="0 0 1200 1200" width={props.width} height={props.height} {...props}>
        <g id="Sonstiges_svg__BaseElements">
            <path fill="#dedede" d="M288.57 286.3l592.16 153.46v759.78H292.81l-4.24-913.24z" />
            <path fill="#e8e8e8" d="M908.36 1199.54H320.44V501.1l590.45-123.71v115.62l-2.53 706.53" />
            <path fill="#dedede" d="M234.23 1175.92h730.11v23.62H234.23z" />
            <path
                fill="#fff"
                d="M451.6 576.07h147.68v179.76H451.6zM641.53 576.07h147.68v179.76H641.53zM641.53 960.49h82.62v91.22h-82.62zM759.49 960.49h82.62v91.22h-82.62z"
            />
            <path
                fill="#f5f5f5"
                d="M451.6 576.07l116.35 179.76H451.6V576.07zM641.53 576.07l116.35 179.76H641.53V576.07z"
            />
        </g>
        <g id="Sonstiges_svg__PrimaryColorElements" fill={props.color}>
            <path d="M428.03 947.67h156.62v227.9H428.03z" />
            <text
                transform="translate(842.14 977.1)"
                fontSize={508.779}
                fontFamily="ProximaNova-Bold,Proxima Nova"
                fontWeight={700}
            >
                {"?"}
            </text>
        </g>
    </svg>
);

export default SvgSonstiges;

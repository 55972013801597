import * as React from "react";

const SvgVollvermietet = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="Vollvermietet_svg__GlobaleEbene"
        viewBox="0 0 1200 1200.46"
        width={props.width}
        height={props.height}
        {...props}
    >
        <g id="Vollvermietet_svg__PrimaryColorElements" fill={props.color}>
            <path d="M287.66 287.46l471.45 122.65-439.41 92.75v673.59l-27.82 1.17-4.22-890.16z" />
            <path d="M907.28 1200.46H319.52V502.2l590.3-123.68v115.59l-2.54 706.35" />
        </g>
        <path opacity={0.06} d="M287.66 287.46l471.45 122.65-439.41 92.75v673.59l-27.82 1.17-4.22-890.16z" />
        <g id="Vollvermietet_svg__BaseElements">
            <path fill="#fff" d="M427.08 948.66h156.58v251.8H427.08z" />
            <path fill="#dedede" d="M233.33 1176.85h729.92v23.61H233.33z" />
            <path
                fill="#fff"
                d="M450.65 577.16h147.64v179.71H450.65zM640.52 577.16h147.64v179.71H640.52zM640.52 961.47h82.6v91.19h-82.6zM758.45 961.47h82.6v91.19h-82.6z"
            />
            <path
                fill="#f2f2f2"
                d="M640.52 577.16l116.32 179.71H640.52V577.16zM450.65 577.16l116.32 179.71H450.65V577.16z"
            />
        </g>
    </svg>
);

export default SvgVollvermietet;
